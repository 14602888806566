@import 'variables.module.scss';

/*ToolTip styles */
.tooltip-label-div {
    width: 100%;
    display: flex;
    justify-content:space-between;
    font-weight:400;
}

.tooltip-date-div {
    display: flex;
    width: max-content;
}

.tooltip-date {
    width: auto;
    color: $tooltip-details;
}

.tooltip-value {
    color: $white;
    margin-left:15px;
}

.tooltip-suffix-value {
    margin-left:5px;
    color: $tooltip-details;
}

 .tooltip-div {
    width: 100%;
    display:flex;
    flex-direction:column;
    flex-wrap: nowrap;
    width: 100%;
    margin: 0 auto;
    padding:5px;
    background-color: $gray-100;
    font-size: 0.75rem;
    font-family:$font-interstate;
    font-weight:400;
}

/// added for tooltips 

/* Tooltips */
.tippy-popper {
    // top: -7px !important;
    pointer-events: none;
    a {
      color: $yellow;
      text-decoration: none;
    }
  }
  .tippy-popper{
    .table-info {
      width: 25rem !important;
        position: relative;
    }
    .table-info-left {
      position:absolute;
      left:-250px !important;
      top: -60px !important;
    }
    .tooltip-info-left {
      position:absolute;
      left: -300px !important;
      top: -50px !important;
    }
  }
  .custom-theme,
  .vis-tooltip {
    background-color: #F9F9FA !important;
    width: fit-content;
    max-width: 700px !important;
    font-size: 12px !important;
    padding: 6px 10px;
    border-radius: 4px;
    word-wrap: break-word;
    color: #2E2E38;
    transition-duration: 20ms !important;
    box-shadow: 0px 2px 4px 0px #1A1A241F;
  }
  
  

  span.overflow-wrapper:not(.allow-text-wrap),
  .overflow-content:not(.allow-text-wrap) {
    display: inline-block;
    white-space: unset;
    text-overflow: ellipsis;
    overflow: hidden;
    /* TODO: check if display: inline-block on .overflow-wrapper can removed */
    a {
      display: inline;
      text-decoration: none;
      color: $yellow;
    }
  }

  .chatTooltip{
    span.overflow-wrapper:not(.allow-text-wrap),
    .overflow-content:not(.allow-text-wrap) {
      display: contents !important;
    }  
  }
  .table-body .table-cell {
    font-size: 13px !important;
    .overflow-wrapper{
      height : 30px !important;
    }
  }
  .overflow-content-class:not(.allow-text-wrap) {
    position: relative;
    top: -9px;
  
  
    /* TODO: check if display: inline-block on .overflow-wrapper can removed */
    a {
      display: inline;
      text-decoration: none;
      color: $yellow;
    }
  }
  
  .overflow-content-class-none:not(.allow-text-wrap) {
    position: relative;
    top: -9px;
    text-transform: none;
    /* TODO: check if display: inline-block on .overflow-wrapper can removed */
    a {
      display: inline;
      text-decoration: none;
      color: $yellow;
    }
  }
