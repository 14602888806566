/* .Frame1 {
  height: 648px;
  left: 32px;
  top: 260px;
  position: absolute;
  border-radius: 4px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
} */

.chatbaropen .Frame54261{
  left:5%;
  }
  .adddocuments_section.chatbaropen .Frame54261{
    left: 0%;
  }
  .chatbaropen .motif-failed-doc-button.failed_button{
    padding: 10px!important;
  }
  .ag-theme-quartz.chatbaropen{
    min-width: 790px !important;
     width: 100% !important; 
     height: 350px !important;
  }
  .adddocuments_section.chatbaropen.datasourcebar-close .ag-theme-quartz{
    min-width: 1040px !important;
  }
  .ag-theme-quartz.chatbarclose{
    min-width: 985px !important;
    width:100% !important; 
    height: 350px !important;
  }
  .adddocuments_section.docchatbarclosed.datasourcebar-close .ag-theme-quartz{
    min-width: 1267px !important;
  }

.Frame1{
  margin-top: 30px;
}

.Frame123{
  /* width: 91%; */
  /* margin: 0 auto; */
}
.adddocuments_section.chatbaropen .Frame123{
  width: 95.5%;
}
.adddocuments_section.chatbaropen.datasourcebar-close .Frame123{
  width: 97.8%;
}
.adddocuments_section.docchatbarclosed .Frame123{
  width: 94.5%;
}
.adddocuments_section.docchatbarclosed.datasourcebar-close .Frame123{
  width: 97.5%;
}


.breadcrumbOpen {
  margin-left: -3%;
  margin-top: -3vh;
}
.breadcrumb {
  margin-left: -2%;
  margin-top: -3vh;
}
/* .Frame123 {
  height: 648px;

  top: 100px;
  position: absolute;
  border-radius: 4px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
} */

  .adddocuments_section.chatbaropen .EmptyFrame123{
  width:94.5%;
  margin-left: 1%;
  }
  .adddocuments_section.docchatbarclosed.datasourcebar-open .EmptyFrame123,.adddocuments_section.docchatbarclosed.datasourcebar-close .EmptyFrame123{
    width: 96.5%;
  }
  .adddocuments_section.chatbaropen.datasourcebar-open .EmptyFrame123,.adddocuments_section.chatbaropen.datasourcebar-close .EmptyFrame123{
    width:95.5%;
  }
  .adddocuments_section.chatbaropen.datasourcebar-close .EmptyFrame123,.adddocuments_section.docchatbarclosed.datasourcebar-close .EmptyFrame123{
    width: 96.5%;
  }
  .adddocuments_section.docchatbarclosed .EmptyFrame123{
    width:117.5%;
    }
  .EmptyFrame123 .title1Width {
    width: 100%;
  }

  .EmptyFrame123 .Frame681{
    position: relative;
    /* left: -8%; */

  }
  .EmptyFrame123 .Frame534012{
    position: relative;
    width:100%;
  }
  .EmptyFrame123 .EmptyState1{
    width:100%;
    display: block;
  }
  .EmptyFrame123 .EmptyState2{
    width:auto;
    display:block;
  }
  .EmptyFrame123 .Rectangle5361{
    border:none;
  }
  .EmptyFrame123 .Frame691{
    width:auto;
    display: block;
  }
.Title12 {
  height: 36px;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: inline-flex;
}
.title12Width {
      width: 100%;
      margin-bottom: 20px;
      margin-top:5px;
}

.TextAndSupportingText {
  flex: 1 1 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
}
.progressmessage .motif-toast{
  right: 26%;
    left: auto;
    top: 25px;
    width: 30%;
    z-index:9999999999;
}

.Text2 {
  align-self: stretch;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
}

.Text6 {
  color: #2e2e38;
  font-size: 18px;
  font-family: EYInterstate;
  font-weight: 400;
  line-height: 28px;
  word-wrap: break-word;
}

/* UPload document styles Started */
.uploadinput {
  width: 1400px !important;
}
.uploaderdiv {
  width: 100% !important;
}
.uploadHeader {
  width: 90% !important;
}
.ContentDivider1 {
  align-self: stretch !important;
  background: rgba(0, 0, 0, 0);
  border: 1px rgba(0, 0, 0, 0) solid;
  justify-content: flex-start !important;
  align-items: center !important;
  gap: 8px;
  display: inline-flex !important;
  width: 100% !important;
}
.Divider1 {
  flex: 1 1 0;
  height: 1px;
  background: #d7d7dc;
}
.Text1 {
  text-align: center;
  color: #9897a6;
  font-size: 14px;
  font-family: EYInterstate;
  font-weight: 300;
  line-height: 20px;
  word-wrap: break-word;
}
.DefaultInputFieldBase1 {
  align-self: stretch;
  height: 70px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}
.Label1 {
  color: #656579;
  font-size: 18px !important;
  font-family: EYInterstate;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
}
.Frame53841 {
  align-self: stretch;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
}
.proceedbtn1 {
  flex-wrap: wrap;
  gap: 6px;
}
.proceedbtn1 button.motif-button.motif-button-primary.motif-button-small{
  padding: 8px 14px !important;
  align-items: center;
  border-radius: var(--btn--border-radius);
  border-color: var(--btn--border-color);
  border-width: var(--btn--border-width);
  border-style: var(--btn--border-style);
  box-shadow: var(--btn--box-shadow);
  cursor: pointer;
  display: flex;
  justify-content: center;
  
  overflow: visible;
  padding: var(--btn--padding);
  position: relative;
  text-align: center;
  text-decoration: none;
  background-color: var(--btn--bg-color);
  color: var(--btn--color);
  font-family: EYInterstate!important;
  font-size: 16px!important;
  font-weight: 400!important;
  line-height: 24px!important;
  letter-spacing: 0em!important;
  text-align: left!important;
}
.proceedbtn1 button.disabled{
  cursor: not-allowed !important;
}
.progressmessage .motif-toast-close-button:hover{
  background: none !important;
}
.Input12 {
  flex: 1 1 0;
  align-self: stretch;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: white;
  border-radius: 4px;
  border: 1px #c3c3cb solid;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
}
.Content1 {
  flex: 1 1 0;
  height: 20px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
}
.Link1 {
  width: 20px;
  height: 20px;
  position: relative;
}
.Vector11 {
  width: 17.92px;
  height: 9.58px;
  left: 1.04px;
  top: 5.21px;
  position: absolute;
  background: #656579;
}
.ChkAll1 {
  display: inline-flex;
  padding-right: 10px;
}
.motifModalupload {
  /* max-width: 1050px !important; */
  max-width: 820px !important;
  max-height: 466px !important;
}
.motifModaluploadDoc {
  max-width: 1057px !important;
}
.motifModaluploadDoc .motif-modal-body::-webkit-scrollbar-thumb,.motifModaluploadDoc .motif-modal-body::-webkit-scrollbar-thumb:hover ,.motifModaluploadDoc::-webkit-scrollbar-thumb,.motifModaluploadDoc::-webkit-scrollbar-thumb:hover,.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb,.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb:hover,.ag-center-cols-viewport::-webkit-scrollbar-thumb,.ag-center-cols-viewport::-webkit-scrollbar-thumb:hover{
  background: #D7D7DC!important;
}
.motifModaluploadDoc::-webkit-scrollbar-track,.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-track,.motifModaluploadDoc .motif-modal-body::-webkit-scrollbar-track,.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-track,.ag-center-cols-viewport::-webkit-scrollbar-track{
  box-shadow: none!important;
}
.Frame53401 {
  /* width: 1200px; */
  /* height: 778px; */
  left: 32px;
  top: 332px;
  margin-top:20px;
}
.Frame54261 {
 
  /* justify-content: center; */
  align-items: center;
  display: inline-flex;
  /* position: sticky; */
  /* width: 100%; */
  position: relative;
  /* margin: 0 auto; */
  width: 90%;
  /* left: 4%; */
}
.Table1 {
  /* width: 1200px; */
  align-self: stretch;
  background: white;
  border: 1px #d7d7dc solid !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  display: grid !important;
}
.CardHeader1 {
  align-self: stretch;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 20px;
  padding-bottom: 20px;
  background: rgba(0, 0, 0, 0);
  border-bottom: 1px #d7d7dc solid;
  justify-content: space-between;
  align-items: flex-start !important;
  display: flex !important;
  width: 100%;
}
.CardTitle1 {
  flex: 1 1 0;
  align-self: stretch;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: flex;
  padding-left: 10px;
}
.TextAndSupportingText1 {
  flex: 1 1 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  margin-left: 5px;
  margin-right: 5px;
}
.text12 {
  align-self: stretch;
  background: rgba(0, 0, 0, 0);
  justify-content: "flex-start";
  align-items: center;
  gap: 8px;
  display: inline-flex;
}
.Text13 {
  color: #2e2e38;
  font-size: 16px;
  font-family: EYInterstate;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}
.SupportingText1 {
  align-self: stretch;
  color: #656579;
  font-size: 14px;
  font-family: EYInterstate;
  font-weight: 300;
  line-height: 20px;
  word-wrap: break-word;
  padding-top: 10px;
}
.SupportingText1Danger {
  align-self: stretch;
  color: var(--Red-700, #a40011) !important;
  font-size: 14px;
  font-family: EYInterstate;
  font-weight: 300;
  line-height: 20px;
  word-wrap: break-word;
  margin-right: 1%;
}
.CardActions1 {
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: flex;
}
.CardActions1 .Button1 button{
  padding: 8px 14px !important;

}
.Button1 {
  border-radius: 4px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  padding-bottom: 10px;
}
.ButtonBase13 {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
  overflow: hidden;
  border: 1px #fdbaab solid;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
}
.Text14 {
  color: #ff4136;
  font-size: 16px;
  font-family: EYInterstate;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}
.Button2 {
  display: inline-flex;
}

.Frame534012 {
  /* width: 1276px; */
  /* //increased the height for disclaimer text on sidebar  */
  height: 535px; 
  left: 0px;
  top: 48x;
  position: absolute;
  margin-top: 50px;
}
.Frame681 {
  top: 232px;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: -webkit-box;
}
.Frame691 {
  width: 360px;
  justify-content: center;
  align-items: "center";
  display: inline-flex;
}
.EmptyState1 {
  width: 512px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
  bottom: 80px;
}
.EmptyState2 {
  width: 352px;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
  display: inline-flex;
}
.Content2 {
  height: 176px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: "20px";
  display: flex;
}
.Text15 {
  align-self: stretch;
  text-align: center;
  color: #2e2e38;
  font-size: 18px;
  font-family: EYInterstate;
  font-weight: 400;
  line-height: 28px;
  word-wrap: break-word;
}
.Rectangle5361 {
  /* width: 1276px; */
  height: 516px;
  left: 0px;
  top: 0px;
  position: absolute;
  border-radius: 4px;
  border: 1px #d7d7dc solid;
}
.Frame534013 {
   width: 1000px;
  height: 400px;
}
.table12 {
  width: 1000px;
  height: 400px;
}
.motif-modal.motif-modal-content.motif-second-upload-modal-chatbarclose.motifModaluploadDoc .Frame534013,.motif-modal.motif-modal-content.motif-second-upload-modal-chatbarclose.motifModaluploadDoc .table12,
.motif-modal.motif-modal-content.motif-second-upload-modal-chatbaropen.motifModaluploadDoc .Frame534013,.motif-modal.motif-modal-content.motif-second-upload-modal-chatbaropen.motifModaluploadDoc .table12 {
  height: auto;
}
.error-message {
  background-color: #fce4e4;
  border: 1px solid #fcc2c3;
  float: left;
  padding: 10px 10px;
  margin-top: 10px;
  width: 900px;
}
.error-text {
  color: #cc0033;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
  line-height: 20px;
  text-shadow: 1px 1px rgba(250, 250, 250, 0.3);
}
.btnRed {
  background-color: #ea011d !important;
}
.btnBlock {
  background-color: #2e2e38 !important;
}

.sharepoint-modal {
  max-width: 1050px !important;

  /* ::ng-deep {
        .motif-table-title-bar-alt {
            min-height: 80px;
        }
    } */
}

.motif-table-title-bar-alt {
  min-height: 80px !important;
}

.sharepoint-modal-footer-container {
  width: 100%;
  height: 48;
  background: rgba(0, 0, 0, 0);
  border: "1px rgba(0, 0, 0, 0) solid";
  justify-content: "flex-end";
  align-items: "center";
  gap: 12;
  display: "inline-flex";
}

.footer-items {
  justify-content: "flex-end";
  align-items: "center";
  gap: 12;
  display: "flex";
}

.footer-button-container {
  text-align: "right";
  color: "#656579";
  font-size: 14;
  font-family: "EYInterstate";
  font-weight: "300";
  word-wrap: "break-word";
}

.main-motif-table-filter {
  padding-right: 24px;
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
}
/* 
.doc-header-container {
  display: flex;
  width: 1376px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border: 1px solid var(--Neutrals-Light-theme-200, #d7d7dc);
  background: var(--Neutrals-Light-theme-00-White, #fff);
} */

.doc-filter-container {
  display: flex;
  padding: 0px 30px;
  justify-content: space-between;
  align-self: stretch;
  flex-direction: column;
}
.doc-filters {
  display: flex;
  gap: 16px;
}
.doc-filter-text {
  width: 319px;
  flex: 1 0 0;
  color: var(--Neutrals-Light-theme-700, #656579);

  /* Caption/Light */
  font-family: EYInterstate;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}
.doc-details-container {
  height: 36px;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  display: flex;
}
.doc-details-states {
  text-align: center;
  color: black;
  font-size: 12px;
  font-family: EYInterstate;
  font-weight: 400;
  line-height: 18px;
  word-wrap: break-word;
}
.reload-doc-image-button {
  border-radius: 4px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}
.motif-button-content {
  display: flex;
  align-items: flex-start;
  border-radius: 4px;
}
.motif-upload-doc-button {
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0));
  background: var(--Neutrals-Light-theme-900, #2e2e38);
}
/* .motif-theme-light {
  overflow: auto !important;
} */
.nodoctext {
  text-align: center;
  color: #656579;
  font-size: 12px;
  font-family: EYInterstate;
  font-weight: 300px;
  word-wrap: break-word;
  padding-top: 16px;
}
.uploaderdiv .motif-file-uploader-label-text {
  width: 230px;
  line-height: 20px;
  font-family: EYInterstate;
}
.sharpointInput .motif-input-component .motif-input:not([type="password"]) {
  padding-left: 30px;
}
.sharepointLinkIcon1 {
  position: absolute;
  bottom: 5px;
  left: 5px;
}
.sharepointLinkIcon2 {
  position: absolute;
  bottom: 35px;
  left: 5px;
}
.searchIcon {
  width: 20px;
  height: 20px;
  z-index: 999;
  position: relative;
  top: 13px;
  left: 30px;
}
.sharePointSearchIcon {
  width: 20px;
  height: 20px;
  z-index: 999;
  position: absolute;
  top: 13px;
  left: 8px;
}
.searchTxtBox input {
  padding-left: 35px !important;
}
.searchTxtBox svg{
  display: none!important;
}
.motif-explore-doc-button{
  margin-left: 7px;
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0));
  background: var(--Neutrals-Light-theme-900, #2e2e38)
}
.motif-failed-doc-button{
  display: none;
}
.motif-upload-doc-button.failed_button{
  margin-left: 7px;
}
.upload_explore_botton *{
font-family: EYInterstate;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: left;


}
.upload_explore_botton button.motif-button.motif-button-primary.motif-button-small,button.explore-right-docs.motif-button.motif-button-primary.motif-button-small{
  align-items: center;
  border-radius: var(--btn--border-radius);
  border-color: var(--btn--border-color);
  border-width: var(--btn--border-width);
  border-style: var(--btn--border-style);
  box-shadow: var(--btn--box-shadow);
  cursor: pointer;
  display: flex;
  font-family: var(--primary-font);
  font-size: var(--btn--font-size);
  font-weight: var(--btn--font-weight);
  justify-content: center;
  line-height: 1.572;
  overflow: visible;
  padding: var(--btn--padding);
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color var(--ease-in-out-fast), background var(--ease-in-out-fast),
    box-shadow var(--ease-in-out-fast), border-color var(--ease-in-out-fast);
  background-color: var(--btn--bg-color);
  color: var(--btn--color);
  -btn-primary--bg-color: var(--btn-primary-hover--bg-color) !important;
--btn-primary--color: var(--btn-primary-hover--color) !important;
--btn-primary--border-color: var(--btn-primary-hover--border-color) !important;
    --btn-primary--border-width: var(--btn-primary-hover--border-width) !important;
    --btn-primary--border-style: var(--btn-primary-hover--border-style) !important;
    --btn-primary--box-shadow: var(--btn-primary-hover--box-shadow) !important;
    --btn-primary--border-radius: var(--btn-primary-hover--border-radius) !important;
    padding:10px 18px 10px 18px;
    font-family: EYInterstate!important;
font-size: 16px!important;
font-weight: 400!important;
line-height: 24px!important;
letter-spacing: 0em!important;
text-align: left!important;
}

/* .upload_explore_botton{
     min-width: 900px;
  margin-right: -36%;
  margin-bottom: 20px;
} */
/* UPload document styles Ended */
.motif-failed-doc-button.hide{
  display: none;
}
.motif-failed-doc-button.failed_button{
  display: block;
  background: #fff;
  padding: 8px 14px !important;
  border: 1px solid #ff0000!important;
  color: #ff0000 !important;
}

@media screen and (min-width: 1367px){
  .ag-theme-quartz.chatbarclose{
    min-width: 1121px !important;
  }
  .ag-theme-quartz.chatbaropen{
    min-width: 920px !important;


  }
  .ag-theme-quartz.chatbaropen,.ag-theme-quartz.chatbarclose{
    height: 390px !important;
  }
 
}
@media screen and (min-width:1501px) and (max-width: 1549px){
  .adddocuments_section.chatbaropen .Frame123{
    width: 94.5%;;
  }
}
@media screen and (min-width: 1504px) and (max-width: 1534px){
  .ag-theme-quartz.chatbarclose {
    min-width: 1125px !important;
}
}
@media screen and (min-width: 1535px){
  .ag-theme-quartz.chatbarclose {
      min-width: 1148px !important;
  }
  .ag-theme-quartz.chatbaropen {
    min-width: 943px !important;
}
.adddocuments_section.chatbaropen.datasourcebar-close .Frame123{
  width: 97%;

}
.adddocuments_section.chatbaropen.datasourcebar-close .ag-theme-quartz{
  min-width: 1208px !important;
}
.adddocuments_section.docchatbarclosed.datasourcebar-close .ag-theme-quartz{
  min-width: 1440px !important;
}
  }
@media screen and (min-width: 1550px){

  .exploredata-close .explore-data-container-child,.explore-data-container-child{
    height:600px!important;
  }
  .ag-theme-quartz.chatbaropen{
    min-width: 1120px !important;
  }
  .ag-theme-quartz.chatbaropen,.ag-theme-quartz.chatbarclose{
    height: 450px !important;
  }
}
@media screen and (max-width: 1400px) {
.ag-theme-quartz.chatbaropen .motif-table-wrapper{
  overflow-y:auto;
}
.ag-theme-quartz.chatbaropen .motif-table-wrapper::-webkit-scrollbar-thumb,.ag-theme-quartz.chatbaropen .motif-table-wrapper::-webkit-scrollbar-thumb:hover{
  background-color: #D7D7DC;
}
.ag-theme-quartz.chatbaropen .motif-table-wrapper::-webkit-scrollbar-track{
  box-shadow: none;
}
}
.ag-theme-quartz .motif-table-wrapper{
  overflow-y:auto;
}
.ag-theme-quartz .motif-table-wrapper::-webkit-scrollbar-thumb,.ag-theme-quartz .motif-table-wrapper::-webkit-scrollbar-thumb:hover{
  background-color: #D7D7DC;
}
.ag-theme-quartz .motif-table-wrapper::-webkit-scrollbar-track{
  box-shadow: none;
}
.motif-modal-overlay .ag-theme-quartz .motif-table-wrapper{
  overflow-y:hidden;
}
@media screen and (max-width: 1357px) {
  .adddocuments_section.chatbaropen.datasourcebar-open.sourcedocs-close .doc-filter-container{
    padding: 0px 5px!important;
  }
  .adddocuments_section.chatbaropen.datasourcebar-open.sourcedocs-close .CardHeader1{
    padding: 20px 5px 20px 0px;
  }
  .adddocuments_section.chatbaropen.datasourcebar-open.sourcedocs-close .CardTitle1{
    padding-left: 5px;
  }
  .ag-theme-quartz.chatbaropen{
    min-width: 672px !important;
    
    
  }
  .adddocuments_section .motif-toast.motif-toast-error{
    right: 26%;
  }
  .ag-theme-quartz.chatbaropen .motif-table-wrapper{ 
    overflow-y:auto;
  }
  .ag-theme-quartz.chatbaropen .motif-table-wrapper::-webkit-scrollbar-thumb,.ag-theme-quartz.chatbaropen .motif-table-wrapper::-webkit-scrollbar-thumb:hover{
    background-color: #D7D7DC;
  }
  .ag-theme-quartz.chatbaropen .motif-table-wrapper::-webkit-scrollbar-track{
    box-shadow: none;
  }
    
 
  .EmptyFrame123 .EmptyState1{
    width:100%;
  }
  .ag-theme-quartz.chatbarclose{
    min-width:868px !important;
  }
  .adddocuments_section.chatbaropen .Frame123{
    width:95%;
  }

  .ag-theme-quartz.chatbaropen,.ag-theme-quartz.chatbarclose{
  height:300px!important;
}
.adddocuments_section.chatbaropen.datasourcebar-close .ag-theme-quartz{
  min-width: 925px !important;
}
  
}

@media screen and (min-width:1348px) and (max-width: 1358px)
{
.ag-theme-quartz.chatbaropen {
    min-width: 757px !important;
    overflow-y: auto;
}
}
@media screen and (min-width:1347px) and (max-width: 1360px) {
  .ag-theme-quartz.chatbarclose{
    min-width: 980px !important;
}
.adddocuments_section.chatbaropen .Frame123{
  width: 93.5%;

}
.adddocuments_section.chatbaropen.datasourcebar-close .Frame123{
  width: 97.8%;
}
.adddocuments_section.chatbaropen.datasourcebar-close .ag-theme-quartz{
  min-width: 1035px!important;
}

  }
  
  @media screen and (min-width:1301px) and (max-width: 1347px) {
    .Frame123{
      width: 90%;
    }
    .adddocuments_section.chatbaropen.datasourcebar-close .Frame123{
      width: 97%;
    }
    .adddocuments_section.chatbaropen .Frame123{
      width: 93%;
    }
    .ag-theme-quartz.chatbarclose{
      min-width: 935px !important;
  }
  .ag-theme-quartz.chatbaropen {
    min-width: 722px !important;
    overflow-y: auto;
}
.exploredata-close .explore-data-container-promptarea-container{
  width:100%!important;
  left:0!important;
}

.exploredata-open button.motif-button.motif-button-primary.motif-button-small.explore-data-container-sendimage{
  right:2.5rem;
}
.adddocuments_section.chatbaropen.datasourcebar-close .ag-theme-quartz{
  min-width: 1012px !important;
}
.adddocuments_section.docchatbarclosed.datasourcebar-close .ag-theme-quartz{
  min-width:1245px!important;
}
    }
@media screen and (min-width: 1300px) and (max-width: 1310px) {
  .adddocuments_section.chatbaropen.datasourcebar-close .ag-theme-quartz{
    min-width: 983px !important;
  }
  .adddocuments_section.docchatbarclosed.datasourcebar-close .ag-theme-quartz{
    min-width:1215px !important;
  }
}
  @media screen and (min-width: 1280px) and (max-width: 1300px) {
    .adddocuments_section.chatbaropen .Frame123{
      width: 93%;
    }
    .ag-theme-quartz.chatbaropen{
      min-width: 684px!important;
    }
    .adddocuments_section.chatbaropen.datasourcebar-close .ag-theme-quartz{
      min-width: 995px!important;
    }
  
    .exploredata-open button.motif-button.motif-button-primary.motif-button-small.explore-data-container-sendimage{
      right:2.5rem!important;
    }
    
  }
@media screen and (max-width: 1280px) {
  .ag-theme-quartz.chatbarclose{
    min-width:900px !important;
}
}
@media screen and (min-width:1230px) and (max-width: 1279px)
{
.ag-theme-quartz.chatbarclose {
    min-width:865px !important;
}
.exploredata-open .explore-prompt-question-container,.exploredata-open .explore-prompt-question-container{
  width:100%;
  position: relative;
}
.explore-prompt-question-container{
  width: 80%!important;
}
.exploredata-open .explore-prompt-question-container-child{
  width:100%;
}
.adddocuments_section.docchatbarclosed.datasourcebar-close .ag-theme-quartz{
  min-width:1146px!important;
}
.adddocuments_section.chatbaropen.datasourcebar-close .ag-theme-quartz{
  min-width: 918px!important;
}

.exploredata-open.exploreDataContainer.datasourcebar-close.sourcedocs-open button.motif-button.motif-button-primary.motif-button-small.explore-data-container-sendimage{
  right:-0.2rem!important;
}

}
@media screen and (max-width: 1149px) {
  .explore-data-container.hidedocuments{
    min-width: 700px;
  }
.ag-theme-quartz.chatbarclose {
    min-width: 713px !important;
} 
}

.motif-pagination-select .motif-dropdown-menu{
  top:-135px!important;
}

.adddocuments_section .motif-toast.motif-toast-error {
  position: absolute;
  top: 1px;
  bottom: auto;
  width: 36%;
  right: 25%;
  left: auto;
  z-index: 9999999999;
}
 .adddocuments_section .motif-toast.motif-toast-success.delete-toast-message{
  position: relative;
  bottom: 30px;
  }
.adddocuments_section .motif-toast-close-button{
  cursor: pointer;
}
.adddocuments_section .motif-toast-close-button:hover{
background: none !important;
}

.motif-h3.motif-modal-headline{
font-size: 18px!important;
font-family: EYInterstate;
line-height: 28px !important;
}
.motif-modal-body .Label1{
  font-size: 14px;
}
.motifModalupload .motif-modal-header .motif-modal--header-icon-button:hover,.motifModaluploadDoc .motif-modal-header .motif-modal--header-icon-button,.motifModaluploadDoc .motif-modal-header .motif-modal--header-icon-button:hover{
  background: none;
}

.motif-toast-info{
  background: #F4F7FC!important;

}
.modal-chatbar-close{
  position:relative;
  left:-10%;
}
.teams-app-upload-modal{
  left:0%;
}
.modal-chatbar-open.modal-datasource-close{
  position: relative;
  left:5%;
}
.modal-chatbar-close.modal-datasource-close{
  position: relative;
  left:0;
}
.sharepoint-title{
font-family: EYInterstate;
font-size: 14px!important;
font-weight: 400;
line-height: 20px;
letter-spacing: 0em;
text-align: left;

}
.sharpointInput #txtSharepoint{
font-family: EYInterstate;
font-size: 16px;
font-weight: 300;
line-height: 24px;
letter-spacing: 0em;
text-align: left;

}
.remove-selected{
  line-height: 20px;;
}

.motif-second-upload-modal-chatbarclose{
position:relative;
left: -3%;
}

.file-upload-error-message {
  color: red;
}

.motifModaluploadDoc .ag-cell-not-inline-editing.ag-cell-normal-height.ag-cell-value div:first-child,.gridimagewithdoc {
  display: flex;

}
.add-document-file-status-div{
  display: "flex";
  justify-content: "center";
  height: "100%";
  align-items: "center";
}
.add-document-file-status {
  display: flex;
  gap: 12px;
}
.add-document-grid-action-button-div {
  margin-top: 8px;
}
.add-document-file-error{
  color:red;
}
.motif-table .ag-checkbox-input-wrapper:not(.ag-disabled) .ag-checkbox-input{
  display: inline-block!important;
}
.customCss1 {
  width: 100%;
  display: flex;
  gap: 10px;
}
.customCss2 {
  display: flex;
  width: 100%;
  gap: 9px;
}
.customCss3 {
  width: 90%;
}
.customCss4 {
  color: red;
}
.customCss5 {
  width: 1000px; 
  height: auto;
}
.customCss6 {
  display: inline-flex;
  float: right;
  gap: 10px;
}
.customCss7 {
  width: 1000px;
  height: auto;
}
.customCss8 {
  width: 100%;
  height: 48px;
  background: rgba(0, 0, 0, 0);
  border: 1px rgba(0, 0, 0, 0) solid;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  display: inline-flex;
}
.customCss9 {
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  display: flex;
}
.customCss10 {
  text-align: right;
  color: #656579; 
  font-size: 14px;
  font-family: EYInterstate;
  font-weight: 300;
  word-wrap: break-word;
}

@media screen and (min-width: 850px) and (max-width: 983px) {
  .adddocuments_section.docchatbarclosed.datasourcebar-close.teams-app .ag-theme-quartz
  {
    min-width: 775px !important;
  }
}

@media screen and (min-width: 984px) and (max-width: 1023px) {
  .adddocuments_section.docchatbarclosed.datasourcebar-close.teams-app .ag-theme-quartz
  {
    min-width: 875px !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1229px) {
  .adddocuments_section.docchatbarclosed.datasourcebar-close.teams-app .ag-theme-quartz
  {
    min-width: 975px !important;
  }
}