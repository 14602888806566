.surveycheckbox {
  position: relative;
  padding-bottom: 16px;
  border-bottom: 1px solid #e6e6e9;
  &__select {
    height: 44px;
    border-radius: 4px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-bottom: 2px;
    border: 1px solid #c3c3cb;
    width: 286px;
  }
  &__tooltip-image {
    transform: translate(5px,3px);
    cursor: pointer;
  }
  &__options {
    width: 286px;
    background: var(--primary-ey-white-ffffff, #fff);
    border-radius: 7px;
    position: absolute;
    z-index: 99999;
    left: 0;
    color: #2e2e38;
    display: flex;
    flex-direction: column;
    fill: var(--Neutrals-Light-theme-00-White, #fff);

    li {
      margin-bottom: 0;
    }
    filter: drop-shadow(0px 0px 0px rgba(66, 66, 81, 0.25))
      drop-shadow(0px 1px 1px rgba(54, 55, 55, 0.5));
    label {
      padding: 8px 8px;
      display: table;
    }
    label:first-child {
      border-bottom: 1px solid #e6e6e9;
      padding: 1px, 0px, 0px, 0px;
      width:100%;
      .surveycheckbox__list-name{
        width: 100%;
      }

    }
  }
  .surveycheckbox__options:has(.surveycheckbox__option-menu) {
    border: 1px solid #c3c3cb;
  }

  &__option-menu {
    margin-right: 8px;
  }
  &__option-menu:hover {
    background-color: #e7e7ea;
  }
  &__option-menu:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
  &__footer {
    display: flex;
    justify-content: center;
    gap: 16px;
    padding: 16px;
  }
  &__title {
    color: #2e2e38;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  &__selected {
    display: flex;
    justify-content: flex-start;
    color: #2e2e38;
    padding-top: 6px;
    flex-wrap: wrap;
    font-size: 12px;
    max-height: 100px;
    overflow-y: auto;
    overflow-x: auto;
  }
  &__selected::-webkit-scrollbar-thumb,&__selected::-webkit-scrollbar-thumb:hover{
    background-color: #D7D7DC;
  }
  &__selected::-webkit-scrollbar-track{
    box-shadow: none;
  }
  &__selected::-webkit-scrollbar{
    height: 10px;
  }
  &__selected-items {
    border-radius: 16px;
    padding: 0px 3px 0px 12px;
    margin: 4px;
    display: flex;
    background: #F3F3F5;
    border: 1px solid #000000;
    ;
  }
  &__scroll-container {
    max-height: 45vh;
    overflow-y: auto;
  }
  &__input{
    display:table-cell!important;
    position: relative;
    top: 2px;
  }
  &__input:checked{
    accent-color: #F3F3F5;
    color:#656579;
    background: #F3F3F5;
    outline: 1px solid;
  }
  &__list-name {
    font-size: 14px;
    font-family: 'EYInterstate';
    font-weight: 300;
    display: table-cell;
    line-height: 20px;

  }
  &__text {
    width: 91%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-size:14px;
    line-height: 20px;
    font-weight: 400;
  }
  &__text-cancel {
    width: 16px;
    cursor: pointer;
  }
  &__header {
    border-bottom: 1px solid #9897a6;
    margin: 0px 23px 0px 21px;
  }
  &__headerText{
    font-family: EYInterstate;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    margin-left: -18px;
    text-align: left;
    color: #2e2e38 !important;
  }
  &__modal {
    max-width: 600px !important;
    border-radius: 8px;
    box-shadow: 0px 16px 32px 0px #23232f14;
    box-shadow: 0px 4px 8px 0px #23232f0f;
  }
  &__modal_overlay{
    background: transparent;
  }
  &__closeButton {
    margin-right: 3px;
    cursor: pointer;
    color: #656579;
    background-color: #fff;
  }
  &__mainDiv {
    display: flex;
  }
  .isSideBarOpen-width {
    width: 233px !important;
  }
  .isSideBarClose-width {
    width: 286px !important;
  }
}
