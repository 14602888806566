@forward '@ey-xd/motif-react/styles.scss';

// @use '@ey-xd/motif-core/src/styles/themes/design-patterns/classic/styles';

.motif-progress-loader-fullscreen {
    margin-top: 0 !important;
    z-index: 2000;
  }
  
  .motif-toast-fixed-bottom {
    z-index: 2000;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  button.motif-button.motif-button-primary.motif-button-small {
    border: none;
    background: none;
    padding: 0;
  }

  .span-bg {
    color: #b14891
  }
  .span-custom1 {
    margin-left:29.8pt; border-collapse:collapse; border:none;
  }
  .span-custom2 {
    width:121.95pt;border:solid windowtext 1.0pt;
    padding:0cm 5.4pt 0cm 5.4pt
  }
  .span-custom3 {
    text-align:center
  }
  .span-custom4 {
    width:121.95pt;border:solid windowtext 1.0pt;
    border-left:none;padding:0cm 5.4pt 0cm 5.4pt
  }
  .span-custom5 {
    width:121.95pt;border:solid windowtext 1.0pt;
    border-left:none;padding:0cm 5.4pt 0cm 5.4pt
  }
  .span-custom6 {
    width:121.95pt;border:solid windowtext 1.0pt;
    border-top:none;padding:0cm 5.4pt 0cm 5.4pt
  }
  .span-custom7 {
    width:121.95pt;border-top:none;border-left:
    none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
    padding:0cm 5.4pt 0cm 5.4pt
  }
  .span-custom8 {
    width:121.95pt;border-top:none;border-left:
    none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
    padding:0cm 5.4pt 0cm 5.4pt
  }
  .span-custom9 {
    width:121.95pt;border:solid windowtext 1.0pt;
    border-top:none;padding:0cm 5.4pt 0cm 5.4pt
  }
  .span-custom10 {
    width:121.95pt;border-top:none;border-left:
    none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
    padding:0cm 5.4pt 0cm 5.4pt
  }
  .span-custom11 {
    width:121.95pt;border-top:none;border-left:
    none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
    padding:0cm 5.4pt 0cm 5.4pt
  }
  .span-custom12 {
    width:121.95pt;border:solid windowtext 1.0pt;
    border-top:none;padding:0cm 5.4pt 0cm 5.4pt
  }
  .span-custom13 {
    width:121.95pt;border-top:none;border-left:
    none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
    padding:0cm 5.4pt 0cm 5.4pt
  }
  .span-custom14 {
    width:121.95pt;border-top:none;border-left:
    none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
    padding:0cm 5.4pt 0cm 5.4pt
  }
  .span-custom15 {
    font-family: "EYInterstate Light"
  }
  .span-custom16 {
    font-family: "EYInterstate Light"
  }
  .termsCustom1 {
    mso-bidi-font-weight:normal
  }
  .termsCustom2 {
    mso-bookmark:_Ref90311320
  }
  .termsCustom3 {
    mso-bookmark:_Hlk30677356
  }
  .termsCustom4 {
    mso-spacerun:yes
  }
  .termsCustom5 {
    mso-bookmark:_Ref340827673
  }
  .termsCustom6 {
    font:7.0pt "Times New Roman"
  }
  .termsCustom7 {
    mso-bidi-font-size: 10.0pt
  }