@import '../SendMessage/SendMessage.scss';

.actions-wrapper {
  display: flex;
  gap: 13px;
}

.btn-openAi {
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: EYInterstate;
  font-size: 14px;
  font-weight: 400;

  &_primary {
    background: #2e2e38;
    color: #fff;
    width: 135px;
    height: 36px;
    top: 166px;
    left: 313px;
    border-radius: 4px;
    border: 1px solid #fff;
  }

  &_secondary {
    width: 80px;
    color: #2E2E38;
    background: #fff;
    width: 72px;
    height: 36px;
    top: 166px;
    left: 460px;
    border-radius: 4px;
    border: 1px solid #C3C3CB;
  }
}

.edit-mode {
  height: auto;
  & input {
    background: transparent;
    border: none;
    color: var(--Neutrals-Light-theme-900, #2e2e38);
    padding: 8px 0px;
    width: 100%;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: EYInterstate;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 133.333% */
    // margin-bottom: 20px;
  }
}
