$alternate-gray: #c4c4cd;
$alternateblack: #23232f;
$blue-2: #a0a0aa;
$black: #000000;
$dark-black: #1a1a24;
$dark-blue: #542ea5;
$gray: #747480;
$gray-3A: #3a3a4a;
$gray-6: #191a24;
$gray-15: #24242d;
$gray-50: #2e2e38;
$gray-5: #919eab;
$gray-40: #404854;
$gray-100: #2e2e3c;
$grey: #747480;
$grey-3A: #3a3a4a;
$grey-6: #191a24;
$grey-15: #24242d;
$grey-50: #2e2e38;
$grey-100: #2e2e3c;
$grey-300: #c4c4cd;
$lightgray: #f6f6fa;
$light-blue: #35a4e8;
$light-brown: #333333;
$white: #fff;
$brown-2: #464653;
$notfound-red: #b9251c;
$white-dark: #ffffff;
$tooltip-details: #dfe3e8;
$yellow: #ffe600;
$font-interstate: 'EYInterstate', 'Segoe UI', sans-serif;
$font-interstate-bold: 'EYInterstateBold', 'Segoe UI', sans-serif;
$logout-modal-width: 500;
$new-project-modal-width: 850;
$feedback-modal-width: 500;
$myjunior-terms-width: 860;
$myjunior-privacy-width: 1100;
$font-feature-setting:
  'clig' off,
  'liga' off;
$grid-gutter-width: 10px;
$gray-10: darken(saturate($brown-2, 0.45), 5.88);
$transition-delay: 0.3s;
$dropdown-active-option-border: $yellow;
$quick-links-arrow: $yellow;
$sticky-height: 75px;
$sticky-wrapper-padding-top: $sticky-height;
$header-top-padding: 5px;
$custom-scrollbar-width: 6px;
$as-tree-struct-left-padding: 16px;
$as-tree-struct-width: 6px;
:export {
  alternategray: $alternate-gray;
  alternateblack: $alternateblack;
  blue2: $blue-2;
  darkblack: $dark-black;
  darkBlue: $dark-blue;
  darkwhite: $white-dark;
  gray: $grey;
  grey3A: $grey-3A;
  grey6: $grey-6;
  grey15: $grey-15;
  grey50: $grey-50;
  grey100: $grey-100;
  grey300: $grey-300;
  lightBlue: $light-blue;
  lightbrown: $light-brown;
  lightgrey: $lightgray;
  white: $white;
  yellow: $yellow;
  fontInterstate: $font-interstate;
  gray40: $gray-40;
  logoutModalWidth: $logout-modal-width;
  newProjectModalWidth: $new-project-modal-width;
  feedbackModalWidth: $feedback-modal-width;
  myJuniorTermsWidth: $myjunior-terms-width;
  myJuniorPrivacyWidth: $myjunior-privacy-width;
  fontFeatureSetting: $font-feature-setting;
  notFoundRed: $notfound-red;
  tooltipDetails: $tooltip-details;
}
