.surveydropdown {
  position: relative;
  padding-bottom: 16px;
  &__options {
    width: 286px;
    background: var(--primary-ey-white-ffffff, #fff);
    border-radius: 7px;
    position: absolute;
    z-index: 99999;
    left: 0;
    color: #000000;
    display: flex;
    flex-direction: column;
    label {
      padding: 8px 16px 0px;
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
    }
  }
  .surveydropdown__options:has(.surveydropdown__option-menu) {
    border: 1px solid rgb(204, 204, 204);
    display: block;
  }
  &__option-menu {
    margin-right: 8px;
  }
  &__option-menu:checked {
    accent-color:#656579;
  }
  &__option-menu:hover {
    background-color: #e7e7ea;
  }
  &__option-menu:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    padding: 16px;
  }
  &__title {
    color: #090d26;
    font-size: 14px;
  }
  &__calender {
    position: absolute;
    right: 21rem;
  }
  .motif-date-picker-wrapper .motif-date-picker .react-date-picker__calendar--open,
  .motif-date-picker-wrapper .motif-date-picker .react-daterange-picker__calendar--open {
    inset: 105% auto auto -3px !important;
  }
  .motif-date-picker-wrapper .motif-calendar {
    width: 20.3rem;
  }
  &__cal-foter {
    top: 30rem;
    position: absolute;
    background: white;
    z-index: 9999;
    box-shadow: 0px 0px 2px #C3C3CB;
    width: 100%;
  }
}
.width-sideBarOpen {
  width: 233px;
}
.width-sideBarClose {
  width: 286px;
}
.bg-selected {
  background: red;
}
.bg-unselected {
  background: transparent;
}
