@import '../../../styles/variables.module.scss';
@import '../../../styles/_mixins.scss';

.openaiquery {
  @include justify-start;
  padding: toRem(4px) toRem(4px) toRem(8px) toRem(8px);
  margin: toRem(4px) toRem(4px);
  align-items: center;
  // margin: 0;
  // padding: toRem(4px) toRem(8px) toRem(8px) toRem(8px);
  &__image-round {
    border-radius: 50%;
    width: toRem(45px);
  }
  &__query-box {
    display: table;
    color: var(--Neutrals-Light-theme-900, #2e2e38); // color: $dark-black;
    background: #ffffff; // background: $white;
    width: 100%;
    text-align: justify;
    // @include box-shadow2;
    @include break-word;
    border-radius: 2px;
    border: 1px solid var(--Neutrals-Light-theme-400, #afaeba);
    font-size: toRem(15px);
    padding: toRem(10px) toRem(45px) toRem(10px) toRem(10px);
    position: relative;
    height: toRem(44px);
    font-weight: 400;
  }

  &__img-container {
    @include start-flex;
    margin-right: toRem(16px);
  }

  &__img-container-dark {
    background: $white;
    @include start-flex;
    border-radius: toRem(2px);
    height: toRem(32px);
    padding: toRem(2px);
    margin-left: toRem(16px);
  }
  &__sidebar-open {
    padding: toRem(4px) toRem(8px) toRem(8px) toRem(8px);
    margin: toRem(4px) toRem(4px);
    align-items: center;
    @include justify-start;
  }
}
