.linkButton {
    color: #FFF;
    font-family: EYInterstate;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.cursor-disabled {
    cursor: not-allowed !important;
}
.cursor-enabled {
    cursor: pointer !important;
}