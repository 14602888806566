.insight-header {
  &__radios {
    color: #2e2e38;
    font-weight: 400;
    line-height: 20px;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    padding: 16px 0px;
  }
  &__reportcount {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #2e2e38;
  }
  &__header-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 3px 55px 8px 5%;
    max-height: 95px;
    .Dropdown-control {
      width: 100% !important;
    }
  }
  &__count{
    font-weight: bold;
  }
}
