@import '../styles/variables.module.scss';

.btn {
  padding: 10px 20px;
  font-family: 'EYInterstate', 'Segoe UI', sans-serif;
  font-size: 0.9375rem;
  line-height: 1.125rem;
  text-align: center;
  border: none;

  &:hover {
    cursor: pointer;
    color: $lightgray;
    background: #4c4e5a;
    border: 1px solid #fff;
  }
}

.yellow-btn {
  color: #000000;
  border: none;
  background-color: $yellow;
  border: 1px solid $yellow;
  &:disabled {
    border: 1px solid #3e404c;
  }
}

.logout-btn,
.next-preview-btn,
.create-project-btn {
  color: #fff;
  border: none;
  background: #24242d;
  border: 1px solid #24242d;
  padding: 10px 20px;
  font-family: 'EYInterstate', 'Segoe UI', sans-serif;
  font-size: 0.9375rem;
  line-height: 1.125rem;
  text-align: center;
  cursor: pointer;

  &:disabled {
    border: 1px solid #3e404c;
  }
}

.secondary-btn , .sector-dropdown-btn {
  border: 1px solid var(--specific-use-colors-default-text-icon-2-e-2-e-38, #2e2e38);
  background: #2e2e38;
  color: var(--specific-use-colors-default-text-icon-2-e-2-e-38, #fff);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  border-radius: 4px;  

  &:disabled {
    border: 1px solid #3e404c;   
  }
}

.feedback-submit-button {
  &:disabled {
    border: 1px solid #3e404c;
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.sector-dropdown-btn {
  width: 50%;
}

.clear-all-btn {
  background: white;
  border: 1px solid #c3c3cb;
  color: #2e2e38;
  font-size: 14px;
  border-radius: 4px;
  width: 50%;
}
.secondary-logout-btn,
.back-cancel-btn {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  background: transparent;
  color: black;
  border: none;
  padding: 10px 20px;
  font-family: 'EYInterstate', 'Segoe UI', sans-serif;
  text-align: center;
  color: black;
  border-left: 0.5px solid rgb(185, 185, 185, 0.8);
  border-right: 0.5px solid rgb(185, 185, 185, 0.8);
  border-top: 0.5px solid rgb(155, 155, 155, 1);
  border-bottom: 0.5px solid rgb(155, 155, 155, 1);
  cursor: pointer;
  border-radius: 4px;
  &:disabled {
    border: 1px solid #3e404c;
  }
}

.next-preview-btn,
.back-cancel-btn,
.create-project-btn {
  line-height: 16px;
  border-radius: 4px;
}

.back-cancel-btn {
  margin-right: 2%;
}

.create-project-btn {
  line-height: 16px;
  border-radius: 2px;
  margin-top: 2px;
}

.advancedsearch-modal button {
  margin-right: 1rem;
}

.advancedsearch-modal {
  border-radius: 15px !important;
}
