.options_wrapper {
  width: 30px;
  height: 30px;
  align-items: center;
  right: 8px;
  top: 3px;
  flex-shrink: 0;
  border-radius: 4px;
  border: none;
  background: transparent;
  color: #3a3a4a;
  position: absolute;
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background: #f3f3f5;
  }
  &__dots {
    margin-bottom: 10px;
  }
  &__list {
    position: absolute;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    top: 35px; //reduced ellipsis section height
    right: 0;
    width: 155px;
    background: white;
    color: #1a1a24;
    display: flex;
    flex-direction: column;
    gap: 5px;
    z-index: 5;

    & button {
      text-align: left;
      background: transparent;
      border: none;
      color: #1a1a24;
      font-family: EYInterstate;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 153.846% */
      // text-transform: capitalize;
      cursor: pointer;
      padding: 8px 10px;
    }
  }
}
