.exploreDataContainer,.adddocuments_section{
  margin-top:36px;
  margin-left:45px;
}

.adddocuments_section.docchatbarclosed,.exploredata-close.exploreDataContainer{
  margin-left:53px;
}
.explore-data-contianer-form-field{
width: 90%;
}
.right-panel-nav-container-row {
  width: 366px;
  height: 33px;
  padding-right: 37px;
  left: 10px;
  top: 78px;
  position: absolute;
  background: rgba(0, 0, 0, 0);
  /* border-bottom: 1px #e6e6e9 solid; */
  justify-content: flex-start;
  align-items: center;
  display: inline-flex;
}

.right-panel-nav-source-doc-container {
  padding-left: 24px;
  padding-right: 24px;
  justify-content: center;
  align-items: flex-start;
  display: inline-flex;
  gap: 24px;
}

.right-panel-nav-source-doc {
  color: #2e2e38;
  font-size: 14px;
  font-family: EYInterstate;
  font-weight: 400;
  word-wrap: break-word;
}

.right-panel-doc-count {
  position: absolute;
  top: 142px;
  left: 34px;
  width: 247px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
}

.right-panel-doc-count-text {
  color: #444;
  font-family: EYInterstate;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.right-panel-doc-count-digit {
  border-radius: 16px;
  border: 1px solid #d7d7dc;
  background: #fff;
  display: flex;
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.right-panel-select-files-text {
  position: absolute;
  top: 185px;
  left: 34px;
  width: 308px;
  height: 70px;
  text-align: left;
  color: #656579;
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.right-panel-select-files-dropdown {
  position: absolute;
  top: 210px;
  left: 20px;
  width: 308px;
  height: 70px;
  text-align: left;
  color: #656579;
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.src-doc-drpdwn-body-container {
  position: relative;
}

.item-container {
  margin: 14px;
}

.motif-dropdown-menu:before {
  display: none;
}

.right-panel-select-files-dropdown>div>div.motif-dropdown-menu {
  left: 0px;
  top: 45px !important;
  width: 100%;
}

.src-doc-filter-type-chip-container {
  border-bottom: 1px #e6e6e9 solid;
  width: 250px;
  top: 25px;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 3px;
}

.src-doc-filter-type-chip-container-wrap {
  border-bottom: 1px #e6e6e9 solid;
  width: 250px;
  top: 25px;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
}

.src-doc-filter-type-blank-container {
  border-bottom: 1px #e6e6e9 solid;
  width: 309px;
  top: 45px;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.src-doc-filter-type {
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0);
  background: #F3F3F5;
  padding: 2px 6px 2px 8px;
  position: relative;
  top: -11px;
  width: 25%;
  font-family: EYInterstate;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #2E2E38;

}

.src-doc-filter-chip-cancel-button {
  bottom: 11px;
  left: 5px;
  align-self: center;
  background: transparent;
  border: none;
  cursor: pointer;
  flex-shrink: 0;
  height: 0px;
  margin-left: -0.14286rem;
  margin-right: 0;
  padding: 0.14286rem;
  position: relative;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  min-width: 0rem;
  border-radius: 50%;
}

.motif-button .motif-icon {
  height: 15px;
}

.right-panel-favorites {
  position: absolute;
  top: 140px;
  left: 34px;
  width: 308px;
  height: 70px;
  text-align: left;
  color: #656579;
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.right-panel-favorites {
  position: absolute;
  top: 140px;
  left: 34px;
  width: 308px;
  height: 70px;
  text-align: left;
  color: #656579;
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.right-panel-history {
  position: absolute;
  top: 140px;
  left: 34px;
  width: 260px;
  height: 70px;
  text-align: left;
  color: #656579;
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.src-doc-dropdown-button {
  font-family: EYInterstate;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  display: flex;
  padding: 8px 10px 5px 10px;
  width: 250px;
  border: 1px solid rgba(225, 225, 230);
}

.src-doc-dropdown-button:hover {
  background-color: #fff;
}

.src-doc-dropdown-text {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:100%;
  text-align: left;
  display: inline-block;
  margin-right: 28px;
  font-family: EYInterstate;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
  
}

.src-doc-drpdwn-icon {
  position: relative;
  left: 0px;
}

.src-doc-drpdwn-icon-with-type-selection {
  position: relative;
  left: 105px;
}

.motif-select-input {
  padding-top: 0px;
}

.right-panel-docs-container {
  left: 34px;
  top: 255px;
  position: absolute;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
  /* overflow-y: scroll;
  height: 600px; */
  overflow-y: auto;
  height: 100%;
}

.right-panel-docs-container-with-noselection {
  left: 32px;
  top: 280px;
  position: absolute;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
  overflow-y: auto;
  height: 100%;
}
.documents-list.right-panel-docs-container-with-noselection
 {
  top:205px;
 }
.history-prompts-list.right-panel-docs-container-with-noselection{
  top:200px;

}
.right-panel-container .allupload-docs{
 position: relative;
  top: -64px;   
}
        
.no-doc-found-txt{
  color: #2E2E38;
  text-align: center;
  font-family: EYInterstate;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.no-doc-instruction-txt {
  color: #656579;
  text-align: center;
  font-family: EYInterstate;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  width: 247px;
  height: 36px;
}

.right-panel-docs-container-with-more-chip {
  left: 34px;
  top: 284px;
  position: absolute;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
  /* overflow-y: scroll;
  height: 600px; */
  overflow-y: auto;
  height: 100%;
}

.right-panel-docs-container {
  left: 34px;
  top: 255px;
  position: absolute;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
}

.motif-select-input {
  padding-top: 0px;
}

.right-panel-doc-checkbox {
  width: 248px;
  align-items: center;
  height: auto;
}

.right-panel-doc-name {
  color: #2e2e38;
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  text-decoration-line: underline;
  text-decoration-color: #7f7f91;
}

.right-panel-active-card {
  padding-bottom: 9px;
  padding-left: 4px;
  padding-right: 4px;
  background: rgba(0, 0, 0, 0);
  border-top-right-radius: 2px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  width: 129px;
}

.right-panel-active-select {
  /* border-bottom: 2px #2e2e38 solid; */
  border: none;
}

.public_source_document {
  width: 80%
}

.public_source_document_icon {
  width: 49px;
  height: 49px;
  position: relative;
  left: 40%;

}

.txt-public-search-enabled {
  color: #000;
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  position: relative;
  left: 26%;
}

.txt-confidential-doc {
  color: #000;
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  text-align: center;
}

.motif-search-input-icon svg {
  display: none;
}

.ActiveConf {
  background: white;
  color: #2e2e38;
}

.manage-docs-button{
  display: inline-block;
  float: left;
    margin-right: 5px;
}
button.motif-button.motif-button-primary.motif-button-small.ActivePublic {
  align-items: center;
  border-radius: var(--btn--border-radius);
  border-width: var(--btn--border-width);
  box-shadow: var(--btn--box-shadow);
  cursor: pointer;
  display: flex;
  font-family: var(--primary-font);
  font-size: var(--btn--font-size);
  font-weight: var(--btn--font-weight);
  justify-content: center;
  overflow: visible;
  padding: var(--btn--padding);
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color var(--ease-in-out-fast), background var(--ease-in-out-fast),
  box-shadow var(--ease-in-out-fast), border-color var(--ease-in-out-fast);
  color: #2e2e38;
  padding:8px 14px;
}

.confpublic {
  color: #2e2e38;
  font-size: 14px;
  font-family: "EYInterstate";
  font-weight: 400;
  word-wrap: break-word;
  border-radius: 5px;
}

.marginpublic {
  margin-left: 5px;
}

.favorites-prompts-list {
  width: 260px;
  height: 904px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
}

.favorites-prompts {
  width: 260px;
  /* height: 106px; */
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: var(--Neutrals-Light-theme-00-White, #fff);
  border-radius: 4px;
  border: 1px solid var(--Neutrals-Light-theme-100, #e6e6e9);
  justify-content: space-between;
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
}

.favorites-prompts:hover {
  border-radius: 4px;
  border: 1px solid var(--Neutrals-Light-theme-100, #e6e6e9);
  background: var(--Neutrals-Light-theme-50, #f3f3f5);
  cursor: pointer;
}

.favorites-prompts-content {
  display: flex;
}

.favorites-prompts-header {
  flex: 1 1 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  max-width: 200px;
  min-width: 200px;
}

.favorites-prompt-title {
  align-self: stretch;
  color: #2e2e38;
  font-size: 14px;
  font-family: EYInterstate;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
}

.favorites-prompt-question {
  align-self: stretch;
  color: #2e2e38;
  font-size: 14px;
  font-family: EYInterstate;
  font-weight: 300;
  line-height: 20px;
  word-wrap: break-word;
}

.favorites-star {
  width: 24px;
  height: 24px;
  position: relative;
}

.favorites-star-icon {
  width: 16.78px;
  height: 16px;
  left: 13px;
  top: 4px;
  position: absolute;
  /* background: #2e2e38; */
}

.favorites-footer {
  height: 18px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  display: flex;
  width: 100%;
  /* align-items: flex-end; */
}

.favorites-date {
  flex: 1 1 0;
  color: #656579;
  font-size: 12px;
  font-family: EYInterstate;
  font-weight: 300;
  line-height: 18px;
  word-wrap: break-word;
  display: flex;
  width: 276px;
  align-items: flex-start;
  gap: 4px;
  flex-shrink: 0;
}

.favorites-broken-link {
  width: 24px;
  height: 24px;
  position: relative;
}

.favorites-broken-link-icon {
  width: 16.78px;
  height: 16px;
  left: 4px;
  top: 4px;
  position: absolute;
  /* background: #2e2e38; */
}

.prompt-type-button {
  flex: 1 1 0;
  height: 36px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: #d7d7dc;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow: hidden;
  border: 1px #c3c3cb solid;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 16px;
}

.active-button {
  border: 1px solid var(--Neutrals-Light-theme-300, #c3c3cb) !important;
  background-color: var(--Neutrals-Light-theme-200, #d7d7dc) !important;
}

#badgepopupmodal {
  max-width: 42% !important;
  max-height: 100% !important;
}

#badgepopuptext {
  text-align: justify;
}

#divspace {
  margin-bottom: 0.4rem;
}

.BadgeFooterButtonContent {
  width: 100%;
  height: 44px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
}

.motif-modal-confidential-body {
  /* width: 980px; */
  height: 316px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
}

#motifModalConfidential {
  max-width: 500px !important;
  max-height: 600px !important;
}

.motif-modal-public {
  max-width: 500px !important;
  max-height: 600px !important;
  
}

.motif-modal-confidential-row-Container {
  flex: 1 1 0;
  height: 40px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
  overflow: hidden;
  border: 1px #c3c3cb solid;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
}

.motif-modal-confidential-row-fileIcon {
  width: 20px;
  height: 20px;
  position: relative;
}

.motif-modal-confidential-row-text {
  color: #2e2e38;
  font-size: 14px;
  font-family: EYInterstate;
  font-weight: 400;
  word-wrap: break-word;
}

.motif-modal-confidential-row {
  gap: 16px;
  display: grid;
}

.motif-modal-public-body {
  padding: 24px;
  background: white;
  box-shadow: 0px 16px 32px rgba(35, 35, 47, 0.08);
  border-radius: 8px;
  border: 1px rgba(0, 0, 0, 0) solid;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 24px;
  display: inline-flex;
}

.motif-modal-public-body-span {
  color: #2e2e38;
  font-size: 16px;
  font-family: EYInterstate;
  font-weight: 300px;
  text-decoration: underline;
  line-height: 24px;
  word-wrap: break-word;
}

.divExploreHeader {
  width: 1270px;
  height: 36px;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: inline-flex;
}

.divexploretext {
  color: #2e2e38;
  font-size: 28px;
  font-family: EYInterstate;
  font-weight: 400;
  word-wrap: break-word;
  margin-top: 1%;
  margin-bottom: 2%;
}

.divRightPanel {
  width: 367px;
  height: 1019px;
  position: relative;
  float: right;
  bottom: 93px;
}

.divFrame123 {
  width: 900px;
  height: 786px;
  position: relative;
  border-radius: 16px;
  border: 1px #d7d7dc solid;
}

.frameexplore1 {
  /* padding: 16px;
left: 13px; */
  top: 80px;
  position: absolute;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.divImgcontainer1 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.imgcontainerText {
  width: 160px;
  height: 18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  display: inline-flex;
}

.imgText1 {
  color: #2e2e38;
  font-size: 16px;
  font-family: EYInterstate;
  font-weight: 700;
  line-height: 18px;
  word-wrap: break-word;
}

.promptboxContainer {
  width: 736px;
  height: 184px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
}

.promptboxtextalign {
  width: 240px;
  background: white;
  border-radius: 6px;
  overflow: hidden;
  border: 1px #d7d7dc solid;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}

.proptboxtext {
  text-align: center;
  color: #656579;
  font-size: 14;
  font-family: EYInterstate;
  font-weight: 300;
  word-wrap: break-word;
  padding-top: 5px;
}

.promptArea1 {
  width: 946px;
  height: 124px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
}

.prompttypeButtons {
  padding-left: 10px;
  padding-right: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.promptQuestionAlign {
  align-self: stretch;
  height: 56px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.asquestiondiv {
  position: absolute;
  margin-left: 810px;
  margin-top: 10px;
  cursor: "pointer";
}

.asksendImgalign {
  border-radius: 4px;
  border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0));
  background: var(--Neutrals-Light-theme-500, #9897a6);
  height: 27px;
}

.promptresponseNote {
  color: #656579;
  font-size: 12px;
  font-family: EYInterstate;
  font-weight: 300;
  word-wrap: break-word;
  position: relative;
  left: 13px;
}

.explore-data-header {
  width: 100%;
  height: 36px;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: inline-flex;
}
.hideview-docs{
  display: inline-block;
  float: right;
  margin-right: 6%;
}
.exploredata-close.datasourcebar-close.sourcedocs-close .hideview-docs{
  margin-right: 2.5%;
}

.exploredata-open.datasourcebar-close .hideview-docs,.exploredata-open.sourcedocs-open .hideview-docs{
  margin-right: 2.8%;
}
.exploredata-open.datasourcebar-close.sourcedocs-open .hideview-docs, .exploredata-open.sourcedocs-close.datasourcebar-open .hideview-docs{
  margin-right: 7%;
}
.exploredata-close.datasourcebar-open.sourcedocs-close .hideview-docs{
  margin-right: 3.5%;
}
.exploredata-open.datasourcebar-close.sourcedocs-open .explore-data-container.visibledocuments{
  min-width: auto;
  left:0;
  width:95%;
}
.exploredata-open.datasourcebar-open .hideview-docs{
  margin-right: 3%;
}
.exploredata-open.datasourcebar-open.sourcedocs-close .hideview-docs{
  margin-right: 4%;
}
.explore-data-text {
  color: #2e2e38;
  font-size: 28px;
  font-family: EYInterstate;
  font-weight: 400;
  word-wrap: break-word;
  margin-bottom: 2%;
  display: inline-block;
  float: left;
  /* padding: 0px 0px 0px 0.625rem; */
}

.explore-data-right-panel {
    width: 290px;
    height:calc(100% - 110px) !important;
    overflow-y: hidden;
    overflow-x: hidden;
    position: relative;
    float: right;
    position: absolute;
    top: 56px;
    right: 0px;
    ;
    z-index: 9;
    background: WHITE;
    z-index: 999;
    border-left: 1px solid lightgray;
}

.explore-data-container {
  min-width: 800px;
  width: 92%;
  left:5%;
  height: auto;
  position: relative;
  border-radius: 16px;
  overflow-y: hidden;
  overflow-x: hidden;
   }

.explore-data-container.hidedocuments{
  width: 100%;
  left:0%;
  position: relative;

}

.explore-data-container-with-quest,.explore-prompt-question-container-child{
  width: 100%;
}
.explore-prompt-question-container{
  width:80%!important;
}

.explore-prompt-question-container-edit-icon{
  float: right;
  margin-left: auto;
}
.exploredata-close .explore-prompt-question-response-doc-note{
  left:0!important;
  padding-right:0!important;
}
.exploredata-close .explore-prompt-question-response-container,.exploredata-close .explore-data-container-promptarea-container{
  width: 100% !important;
  left:0 !important;
}
.exploredata-close .explore-data-container-prompt-question-container .motif-form-field-text-input,.exploredata-close .explore-data-container-prompt-question-container .explore-field-text-area-input{
  width:99%!important;
}

.exploredata-close button.motif-button.motif-button-primary.motif-button-small.explore-data-container-sendimage{
  right:0!important;
}
.exploredata-close .explore-data-container-prompt-question-container-child{
  margin-left:0;
  right:14px;
}
.exploredata-close.datasourcebar-close.sourcedocs-close .explore-data-container-prompt-question-container-child{
  right:16px;
}

.exploredata-close .explore-data-container.visibledocuments{
  width: 100%!important;
  left:0!important;

}
.exploredata-close .explore-data-container.visibledocuments .explore-data-container-child{
width:94%;
}
.exploredata-open button.motif-button.motif-button-primary.motif-button-small.explore-data-container-sendimage{
  right:0.8rem;
}

.explore-prompt-req-res{
margin:10px;
margin-left:0px;
}

.explore-data-container-child {
  margin: 16px;
  margin-left:0px;
  top: 10px;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  width: 80%;
  height: 535px;
  position: relative;
  overflow: hidden;
  overflow-y: auto;
}
.explore-data-container-child::-webkit-scrollbar-thumb,.documents-list::-webkit-scrollbar-thumb,.documents-list::-webkit-scrollbar-thumb:hover,.explore-data-container-child::-webkit-scrollbar-thumb:hover{
  background-color: #D7D7DC;
}
.explore-data-container-child::-webkit-scrollbar-track,.documents-list::-webkit-scrollbar-track{
  box-shadow: none;
}

.documents-list{
 height: 430px
}
.documents-list.right-panel-docs-container-with-noselection{
  height: calc(100% - 154px)!important;
}
.documents-list.right-panel-docs-container-with-more-chip{
  height: calc(100% - 234px)!important;
}
.documents-list.right-panel-docs-container{
  height: calc(100% - 205px)!important;
}

.explore-data-container-image-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right:60px;
  margin-bottom: 80px;
}

.explore-data-container-image-container-child {
  width: 200px;
  height: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
  display: inline-flex;
  margin-top: -20px;
  margin-left: 10px;
}

.explore-data-container-image-container-text {
  color: #2e2e38;
  font-size: 16px;
  font-family: EYInterstate;
  font-weight: 700;
  line-height: 18px;
  word-wrap: break-word;
  margin-top:60px;
}

.explore-data-container-promptbox-container {
  width: 100%;
  height: 184px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: inline;
  /* margin: 0 auto; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.explore-data-container-promptbox-container-child {
  width: 240px;
  /* background: white; */
  /* border-radius: 6px; */
  overflow: hidden;
  /* border: 1px #d7d7dc solid; */
  flex-direction: column;
  display: inline-table;
  height: 80px;
  margin-left: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.explore-data-container-promptbox-container-text {
  text-align: center;
  color: #656579;
  font-size: 14px;
  font-family: EYInterstate;
  font-weight: 300;
  word-wrap: break-word;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0em;

}

.explore-data-container-promptarea-container {
  width: 946px;
  height: 124px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
  left: 0px;
  padding-top: 10px;
}

.explore-data-container-promptarea-buttons {
  padding-left: 10px;
  padding-right: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.explore-data-container-prompt-question-container {
  align-self: stretch;
  height: 56px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* gap: 6px; */

  display: flex;
  position: relative;
  left: 50px;
  width: 95%;
}
.explore-data-container-prompt-question-container .motif-input {
  padding-right: 50px !important;
}

.explore-data-container-prompt-question-container-child {
  position: absolute;
  margin-left: 91%;
  margin-top: 10px;
  cursor: pointer;
}
.exploredata-open .explore-data-container.hidedocuments .explore-data-container-prompt-question-container-child,.exploredata-open .explore-data-container.visibledocuments .explore-data-container-prompt-question-container-child{
  margin-left: 0;
  right:25px;
}
.exploredata-open button.motif-button.motif-button-primary.motif-button-small.explore-data-container-sendimage{
  right:0!important;
}
.exploredata-open .explore-prompt-question-response-container-child{
left:44px!important;
width:94%!important;
}
button.motif-button.motif-button-primary.motif-button-small.explore-data-container-sendimage {

  align-items: center;
  border-radius: var(--btn--border-radius);
  border-color: var(--btn--border-color);
  border-width: var(--btn--border-width);
  border-style: var(--btn--border-style);
  box-shadow: var(--btn--box-shadow);
  cursor: pointer;
  display: flex;
  font-family: var(--primary-font);
  font-size: var(--btn--font-size);
  font-weight: var(--btn--font-weight);
  justify-content: center;
  line-height: 1.572;
  overflow: visible;
  padding: var(--btn--padding);
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color var(--ease-in-out-fast),
  box-shadow var(--ease-in-out-fast), border-color var(--ease-in-out-fast);
  color: var(--btn--color);
  border-radius: 4px;
  /* border: 1px solid rgba(0, 0, 0, 0);
  background: #9897a6;
  height: 27px;
  position: relative; */
  bottom: 6px;
  right: 124px;
  padding:8px 10px;
  min-width: 3rem !important;
  background: #9897A6;
  border: 1px solid #9897A6;
  right:0.8rem;
}
.exploredata-open .explore-data-container-sendimage{
 right:74px;
}

.explore-data-container-response-note {
  color: #656579;
  word-wrap: break-word;
  position: relative;
  font-family: EYInterstate;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  line-height: 18px;

}

.explore-prompt-question-container {
  height: auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  float:right;
}

.explore-prompt-question-container-child {
  height: 20px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
}

.explore-prompt-question-container-text {
  color: #2e2e38;
  font-size: 14px;
  font-family: EYInterstate;
  font-weight: 400;
  line-height: 20;
  word-wrap: break-word;
}

.explore-prompt-question-container-date {
  color: #656579;
  font-size: 12px;
  font-family: EYInterstate;
  font-weight: 300;
  line-height: 18px;
  word-wrap: break-word;
}

.explore-prompt-question-header {
  align-self: stretch;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #2e2e38;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
}

.explore-prompt-question-text {
  flex: 1 1 0;
  color: #f9f9fa;
  font-size: 16px;
  font-family: EYInterstate;
  font-weight: 300;
  word-wrap: break-word;
  word-break: break-word;
}

.explore-prompt-question-edit-promt-container {
  width: 640px;
  height: 36px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  display: inline-flex;
}

.explore-prompt-question-response-doc-note {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  padding-right: 70px;
  position: relative;
  left: 60px;
  margin-bottom:20px;
  margin-top:15px;
}

.explore-prompt-question-response-doc-text {
  width: 922;
  color: #2e2e38;
  font-size: 14px;
  font-family: EYInterstate;
  font-weight: 400;
  word-wrap: break-word;
}

.docContaineralign {
  align-self: stretch;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
  overflow: hidden;
  border: 1px #c3c3cb solid;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
  font-family: EYInterstate;
  font-weight: 400;
}

.contentfamily {
  font-family: EYInterstate;
  font-weight: 400;
  word-wrap: break-word;
  font-size: 14px;
  color :rgb(46, 46, 56);
}

.explore-prompt-question-response-doc-container {
  flex: 1 1 0;
  height: 36px;
  /* padding-top: 8px;
  padding-bottom: 8px; */
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
  /* overflow: hidden; */
  /* border: 1px #c3c3cb solid; */
  justify-content: flex-start;
  align-items: center;
  display: flex;
  width: 100%;
}
.explore-prompt-question-response-doc-container.contentfamily{
  margin-right: 5px;
  border-left: none;
  /* border-right: none; */
  border: 1px #c3c3cb solid;
  padding-left: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  height: 44px;
  border-left: none;
  margin-left: -1%;
}
.explore-prompt-question-response-doc-fileicon {
  /* width: 20px;
  height: 20px; */
  position: relative;
  position: relative;
  border-left: 1px #c3c3cb solid;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px #c3c3cb solid;
  border-top: 1px #c3c3cb solid;
  border-right: none;
  padding-left: 1%;
}
.explore-prompt-question-response-doc-fileicon img,.motif-modal-confidential-row-fileIcon img {
  cursor:pointer;
}
.explore-prompt-question-response-icon {
  padding: 6px;
  background: #656579;
  border-radius: 32px;
  border: 1px #656579 solid;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.explore-prompt-copy-response-icon {
  padding: 6px;
  border-radius: 32px;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  position: relative;
  right: 5%;
  /* width:100px; */
}

.explore-prompt-question-response-container {
  width: 840px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  position: relative;
  left: 50px;
}

.explore-prompt-question-response-container-child {
  align-self: stretch;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #f3f3f5;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  display: flex;
  position: relative;
  left: 44px;
  bottom: 39px;
  width: 95%;
}

.explore-prompt-question-response {
  align-self: stretch;
  color: #2e2e38;
  font-size: 16px;
  font-family: EYInterstate;
  font-weight: 300;
  word-wrap: break-word;
}
.explore-prompt-question-response *{
  font-family: EYInterstate !important;
}
.explore-prompt-question-response p{
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
}



.right-panel-container {
  width: 280px;
  height: 100%;
  position: relative;
  right: 15px;
  top:-50px;
    /* bottom: 103px; */
    /*bottom: 125px;*/
  /* bottom: 93px; */
}

.right-panel-container-newprompt-container {
  width: 280px;
  left: 32px;
  top: 24px;
  position: absolute;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
}

.right-panel-container-newprompt-heder {
  flex: 1 1 0;
  height: 36px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: #2e2e38;
  border-radius: 4px;
  overflow: hidden;
  border: 1px rgba(0, 0, 0, 0) solid;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
}

.right-panel-container-newprompt-text {
  color: #f3f3f5;
  font-size: 14px;
  font-family: EYInterstate;
  font-weight: 400;
  word-wrap: break-word;
}

.right-panel-container-favourate-tab {
  color: #656579;
  font-size: 14px;
  font-family: EYInterstate;
  font-weight: 400;
  word-wrap: break-word;
}

.explore-prompt-copy-response-icon>button {
  background: transparent;
  border: none;
}

.motif-input-clear-button {
  display: none !important;
}

/* Add all the css before this, this is for chat window spinner*/
.dot-typing {
  position: relative;
  left: -9977px;
  bottom: -10px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #7F7F91;
  color: #7F7F91;
  box-shadow: 9984px 0 0 0 #7F7F91, 9994px 0 0 0 #7F7F91, 10004px 0 0 0 #7F7F91;
  animation: dot-typing 1.5s infinite linear;
}
.dotwithgenresponse .stage{
width: 40px;
height: 28px;
background: #E6E6E9;
position: relative;
top: 45px;
border-radius: 0px 4px 4px 4px;
}
.dotwithgenresponse{
  width: 100%;
}
.dotwithgenresponse .genname{
  color: #000;
  margin-left: 45px;
  bottom: -20px;
  font-family: EYInterstate;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  position: relative;

}

.aligned {
  display: flex;
  align-items: center;
}

.spaninfo {
  padding: 10px;
}

.linktext {
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  text-decoration-line: none;
  color: #0F69AE;
  margin-left: 5px;
  margin-right: 5px;  
}

.breadcrum-project {
  font-size: 12px;
  font-family: EYInterstate;
  font-weight: bold;
  line-height: 18px;
  word-wrap: break-word;
}

@keyframes dot-typing {
  0% {
    box-shadow: 9984px 0 0 0 #7F7F91, 9994px 0 0 0 #7F7F91, 10004px 0 0 0 #7F7F91;
  }

  16.667% {
    box-shadow: 9984px -10px 0 0 #7F7F91, 9994px 0 0 0 #7F7F91, 10004px 0 0 0 #7F7F91;
  }

  33.333% {
    box-shadow: 9984px 0 0 0 #7F7F91, 9994px 0 0 0 #7F7F91, 10004px 0 0 0 #7F7F91;
  }

  50% {
    box-shadow: 9984px 0 0 0 #7F7F91, 9994px -10px 0 0 #7F7F91, 10004px 0 0 0 #7F7F91;
  }

  66.667% {
    box-shadow: 9984px 0 0 0 #7F7F91, 9994px 0 0 0 #7F7F91, 10004px 0 0 0 #7F7F91;
  }

  83.333% {
    box-shadow: 9984px 0 0 0 #7F7F91, 9994px 0 0 0 #7F7F91, 10004px -10px 0 0 #7F7F91;
  }

  100% {
    box-shadow: 9984px 0 0 0 #7F7F91, 9994px 0 0 0 #7F7F91, 10004px 0 0 0 #7F7F91;
  }
}

.explore-rp-search-icon {
  width: 20px;
  height: 20px;
  z-index: 999;
  position: absolute;
  left: 14px;
  top: 13px;
}

.explore-rp-search-textbox input{
  padding-left: 40px !important;
}

.explore-rp-search-textbox input::placeholder {
  color: #656579;
  font-family: EYInterstate;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.add-prompt-modal-header{
  border: none !important;
}
.right-panel-doc-count{
  color:#000;
}

.exploredata-open .explore-data-container.hidedocuments{
  width:100% ;
  left: 0;
}
.explore-data-text {
    /* margin-left: 3.5%; */
    color: #2e2e38;
    font-size: 18px;
    font-family: EYInterstate;
    font-weight: 400;
    line-height: 28px;
    word-wrap: break-word;
}

.exploredata-close .explore-data-container-child,.exploredata-close .explore-prompt-question-response-container{
  width: 91%;
}
.exploredata-close .explore-data-container-child{
  width: 93%;
}
.exploredata-close.datasourcebar-open.sourcedocs-close .explore-data-container-child{
  width: 96%;
}
.exploredata-open.datasourcebar-close .explore-data-container-child,.exploredata-open.sourcedocs-open .explore-data-container-child{
  width: 97%;
}

.exploredata-close.datasourcebar-close.sourcedocs-close .explore-data-container-child{
  width: 97%;
}
.exploredata-close .explore-data-container-promptarea-container{
width: 100%;
}
.exploredata-close .explore-data-container-prompt-question-container{
width: 100%;
left:0;
}

.exploredata-close .explore-data-container-prompt-question-container .motif-form-field-text-input,.exploredata-close .explore-data-container-prompt-question-container .explore-field-text-area-input{
  width: 95%;
}
.exploredata-open  .explore-data-container-child{
  width:93%;
}
.exploredata-open.datasourcebar-open .explore-data-container-child{
  width: 95%;
}
.exploredata-open .explore-prompt-question-response-container{
  width: 100%;
  left:0;
}


.exploredata-open .explore-prompt-question-response-doc-note{
  padding-right:0;
  left:0;
}
.explore-data-container-image-container-tagline{
color: #8D8D8D;
font-size: 14px;
line-height: 20px;
font-weight: 400;
font-family: 'EYInterstate';
}
button.motif-button.motif-button-primary.motif-button-small.seeall{
  align-items: center;
  border-radius: var(--btn--border-radius);
  border-color: var(--btn--border-color);
  border-width: var(--btn--border-width);
  border-style: var(--btn--border-style);
  box-shadow: var(--btn--box-shadow);
  cursor: pointer;
  display: flex;
  font-family: var(--primary-font);
  font-size: var(--btn--font-size);
  font-weight: var(--btn--font-weight);
  justify-content: center;
  line-height: 1.572;
  overflow: visible;
  padding: var(--btn--padding);
  position: relative;
  text-align: center;
  text-decoration: none;
  background-color: var(--btn--bg-color);
  color: var(--btn--color);
}
 /* .exploredata-close button.motif-button.motif-button-primary.motif-button-small.explore-data-container-sendimage{
    right: 7.2rem;
  } */
@media screen and (max-width:1360px)
{
  .right-panel-doc-checkbox{
    width:242px;
  }

  .adddocuments_section.docchatbarclosed, .exploredata-close.exploreDataContainer{
    margin-left:50px;
  }
  .exploredata-open .explore-data-container-promptarea-container{
    width: 100% !important;
  }
  .exploredata-open .explore-data-container.hidedocuments .explore-data-container-prompt-question-container-child,.exploredata-open .explore-data-container.visibledocuments .explore-data-container-prompt-question-container-child{
    right:24px;
  }
  .exploredata-open .explore-prompt-question-response-container-child {
    width: 92% !important;
  }
  .exploredata-open.datasourcebar-close.sourcedocs-open .explore-data-container.visibledocuments{
    width:98%;
  }
  .exploredata-open .explore-data-container-prompt-question-container{
    width: 100%;
    left:50px;
  }
  .exploredata-close .explore-data-container-promptarea-container{
    left: 0px !important;
  }
  .exploredata-open .explore-data-container.visibledocuments{
    min-width: 622px;
    width: 92%;
  }
  .exploredata-open .explore-data-container-response-note{
    left:0;
    width:90%;
  }
  }
  .exploredata-open .explore-data-container.visibledocuments{
    width: 100%;
    left:0;
    }
    .exploredata-open .explore-data-container-prompt-question-container .motif-form-field-text-input,.exploredata-open .explore-data-container-prompt-question-container .explore-field-text-area-input{
      width:98% !important;
    }
    .exploredata-open.exploreDataContainer.datasourcebar-close.sourcedocs-close .explore-data-container-prompt-question-container-child{
      right: 29px;
      margin-top: 10px;
    }
  @media screen and (min-width: 1367px) {
    .documents-list{
    height: 530px;
    }
    .exploredata-open .explore-data-container.hidedocuments .explore-data-container-prompt-question-container-child,.exploredata-open .explore-data-container.visibledocuments .explore-data-container-prompt-question-container-child{
      right:24px;
    }
    .exploredata-open .explore-prompt-question-response-container-child{
      width: 95%!important;
    }
   .exploredata-close .explore-prompt-question-response-container-child{
    width: 95.6% !important;
    }
    .explore-data-container.hidedocuments{
      left:4%;
      min-width: 700px;
      width: 95.5%;
    }
    .exploredata-close.datasourcebar-close.sourcedocs-close .explore-data-container.hidedocuments{
      width: 99%;
    }

  .exploredata-close .explore-data-container.hidedocuments{
    left:4.1%
  }
  .exploredata-open  button.motif-button.motif-button-primary.motif-button-small.explore-data-container-sendimage{
    right:1.6rem;
  }


  .progressmessage .motif-toast{
    right:23%;
  }
  .explore-data-container-prompt-question-container {
    width: 100% !important;
}
.exploredata-close .explore-data-container-prompt-question-container,.exploredata-open .explore-data-container-prompt-question-container {
  left: 0px !important;
}
.exploredata-open .explore-data-container-prompt-question-container{
  width: 98%;
}


/* .exploredata-close button.motif-button.motif-button-primary.motif-button-small.explore-data-container-sendimage{
  right: 7.5rem;
} */

.exploredata-open .explore-data-container-promptarea-container{
width: 100%;
left:0;
}
.explore-data-container-child{
  height: 550px;
}
.adddocuments_section.docchatbarclosed, .exploredata-close.exploreDataContainer{
  margin-left:57px;
}
.exploreDataContainer.exploredata-open, .adddocuments_section.chatbaropen{
  margin-left: 50px;

}
.adddocuments_section.chatbaropen .Frame123{
  width: 95.5%;
}
.adddocuments_section.chatbaropen.datasourcebar-close .Frame123{
  width: 98%;
}
.ag-theme-quartz.chatbaropen{
  min-width: 928px !important;
}

.exploredata-open .explore-data-container-child,.exploredata-open .explore-data-container.hidedocuments .explore-data-container-child{
width:93%;
}
.exploredata-open.datasourcebar-close.sourcedocs-close .explore-data-container-child{
  width: 97%;
}
.exploredata-close .explore-data-container.hidedocuments .explore-prompt-question-container{
  margin-right: 0px;
}
.exploredata-close .explore-data-container.hidedocuments .explore-data-container-child{
  width:94%;
}
.exploredata-close .explore-data-container.hidedocuments .explore-prompt-question-response-container,.exploredata-open.datasourcebar-open.sourcedocs-close .explore-data-container-child{
  width:95.5%;
}
.exploredata-close.datasourcebar-open.sourcedocs-close .explore-data-container.hidedocuments{
  width:97% ;
}
}
@media screen and (max-width:1350px) {
  .exploredata-open.datasourcebar-open.sourcedocs-close .hideview-docs{
    margin-right: 5%;
  }
  .exploredata-close.datasourcebar-open.sourcedocs-close .explore-data-container-child{
    width: 96%;
  }
}
@media screen and (min-width: 1400px) and (max-width:1500px)
{
  .exploredata-close .explore-data-container-promptarea-container{
    width: 100%!important;
  }
  /* .exploredata-close button.motif-button.motif-button-primary.motif-button-small.explore-data-container-sendimage{
    right:7rem;
  } */
  .exploredata-open button.motif-button.motif-button-primary.motif-button-small.explore-data-container-sendimage{
    right:1.8rem;
  }
  .adddocuments_section.docchatbarclosed, .exploredata-close.exploreDataContainer{
    margin-left:54px;
  }
  .ag-theme-quartz.chatbaropen {
    min-width: 790px !important;
}
.adddocuments_section.chatbaropen.datasourcebar-open .ag-theme-quartz.chatbaropen{
min-width: 825px !important;
}
.ag-theme-quartz.chatbarclose {
  min-width: 1020px !important;
}
.adddocuments_section.chatbaropen.datasourcebar-close .ag-theme-quartz{
    min-width:1071px !important;
}
.adddocuments_section.docchatbarclosed.datasourcebar-close .ag-theme-quartz{
  min-width:1309px !important;
}
.adddocuments_section.chatbaropen.datasourcebar-close .Frame123{
  width:97%;
}
}
@media screen and (min-width: 1500px) and (max-width:1534px){
  .ag-theme-quartz.chatbaropen{
    min-width: 928px !important;
  }
  .ag-theme-quartz.chatbarclose {
    min-width: 1121px !important;
}
.adddocuments_section.docchatbarclosed.datasourcebar-close .ag-theme-quartz{
  min-width: 1410px !important;
}
.adddocuments_section.chatbaropen.datasourcebar-close.sourcedocs-close .ag-theme-quartz{
  min-width: 1183px !important;
}
.exploredata-close .explore-data-container.hidedocuments .explore-data-container-child{
  width: 98%;
}
.exploredata-close.datasourcebar-close.sourcedocs-close .explore-data-container.hidedocuments .explore-data-container-child{
width: 94%;
}
.exploredata-close.datasourcebar-open.sourcedocs-close .explore-data-container.hidedocuments .explore-data-container-child{
width: 95%;
}

}
@media screen and (min-width: 1508px){
  .adddocuments_section.docchatbarclosed.datasourcebar-close .ag-theme-quartz{
    min-width: 1404px !important;
  
  }
}

@media screen and (min-width: 1535px){
  .ag-theme-quartz.chatbaropen {
    min-width: 945px !important;
}
  }
@media screen and (min-width: 1550px) {
  .documents-list{
    height: 550px;
   }
   .exploredata-close .explore-data-container-prompt-question-container-child,.exploredata-close.datasourcebar-close.sourcedocs-close .explore-data-container-prompt-question-container-child{
    right:18px;
   }
   .exploredata-close .explore-data-container.visibledocuments .explore-data-container-child{
    width: 94.5%!important;
   }
   .exploredata-close .explore-prompt-question-response-container-child{
    width: 96% !important;
   }
   .exploredata-open .explore-prompt-question-response-container-child{
    width: 95.9%!important;
   }
  .explore-data-container.hidedocuments{
    left:3%;
    min-width: 700px;
  }
  /* .exploredata-close button.motif-button.motif-button-primary.motif-button-small.explore-data-container-sendimage{
    right:7.5rem ;
  } */
  .exploredata-close .explore-data-container.hidedocuments{
    left:3.1%;
  }

  .progressmessage .motif-toast{
    right:20%;
  }

  .explore-data-right-panel{

    width:280px;
  }
  .exploredata-close .explore-data-container.visibledocuments .motif-button.motif-button-primary.motif-button-small.explore-data-container-sendimage {
  right:155px;
  }

    .explore-prompt-copy-response-icon{
      right:9%;
    }
  .right-panel-container{
    right: 21px;
  }

  .exploredata-close .explore-data-container-promptarea-container{
    width: 100% !important;
  }

  .exploredata-close .explore-data-container-sendimage{
    right:140px;
  }

  .exploredata-open .explore-data-container-promptarea-container
  {
    width:100%;
  }
  .adddocuments_section.docchatbarclosed, .exploredata-close.exploreDataContainer{
    margin-left:64px;
  }
  .exploreDataContainer.exploredata-open, .adddocuments_section.chatbaropen{
    margin-left: 57px;
  }
  .exploredata-open button.motif-button.motif-button-primary.motif-button-small.explore-data-container-sendimage{
    right:15px;
  }
  .exploredata-open .explore-data-container-child, .exploredata-open .explore-data-container.hidedocuments .explore-data-container-child{
    width:95%;
  }
  .exploredata-open.datasourcebar-close.sourcedocs-close .explore-data-container.hidedocuments .explore-data-container-child{
   width: 97%;
   }
   .exploredata-open.datasourcebar-open.sourcedocs-close .explore-data-container-child{
     width: 95%!important;
   }
   
  .exploredata-close .explore-data-container.hidedocuments .explore-prompt-question-container{
    margin-right: 0px;
  }
  .exploredata-close .explore-data-container.hidedocuments .explore-data-container-child{
    width: 99%;
  }
  .exploredata-close .explore-data-container.hidedocuments{
    left:0;
  }
  .exploredata-close .explore-data-container.hidedocuments .explore-prompt-question-response-container{
    width:96%;
  }
  .exploredata-open .explore-data-container.hidedocuments .explore-data-container-prompt-question-container-child,.exploredata-open .explore-data-container.visibledocuments .explore-data-container-prompt-question-container-child{
    right:26px;
  }
  .exploredata-open.exploreDataContainer.datasourcebar-close.sourcedocs-close .explore-data-container-prompt-question-container-child{
    right:31px;
  }
  .exploredata-open.datasourcebar-close .explore-data-container-child, .exploredata-open.sourcedocs-open .explore-data-container-child{
    width: 97%;
  }
} 

@media screen and (min-width:1600px) {
  .adddocuments_section.docchatbarclosed, .exploredata-close.exploreDataContainer{
    margin-left:63px;
  }
  .exploredata-open button.motif-button.motif-button-primary.motif-button-small.explore-data-container-sendimage{
    right: 1.6rem;
  }
  .ag-theme-quartz.chatbarclose {
      min-width: 1200px !important;
  }
  .ag-theme-quartz.chatbaropen {
    min-width: 995px !important;
  }
  .documents-list{
    height: 600px;
   }
  .adddocuments_section.chatbaropen.datasourcebar-close .ag-theme-quartz{
    min-width:1271px!important;
  }
  .adddocuments_section.docchatbarclosed.datasourcebar-close .ag-theme-quartz{
    min-width: 1494px !important;
  }
            
}

@media screen and (min-width:1685px){
  .ag-theme-quartz.chatbaropen{
    min-width: 1060px !important;
  }
  .adddocuments_section.chatbaropen.datasourcebar-close .ag-theme-quartz{
    min-width: 1358px !important;
  }
  .chatbaropen .Frame54261{
    left:4%;
  }
  .ag-theme-quartz.chatbarclose{
    min-width: 1274px !important;
  }
  .adddocuments_section.chatbaropen .Frame123{
    width: 94.5%;
  
  }
  .adddocuments_section.chatbaropen.datasourcebar-close.sourcedocs-close .Frame123{
    width: 98%;
  }
  /* .exploredata-close button.motif-button.motif-button-primary.motif-button-small.explore-data-container-sendimage{
    right: 7.8rem;
  
  } */
}
@media screen and (min-width: 1651px) and (max-width: 1698px){
  .adddocuments_section.docchatbarclosed.datasourcebar-close .ag-theme-quartz{
    min-width: 1579px !important;
  }
  .exploredata-open.datasourcebar-close .explore-data-container-child, .exploredata-open.sourcedocs-open .explore-data-container-child{
    width: 98%;
  
  }
  .exploredata-close.exploreDataContainer.datasourcebar-close.sourcedocs-close button.motif-button.motif-button-primary.motif-button-small.explore-data-container-sendimage{
    right: 0.2rem !important;
  }

}
@media screen and (min-width:1698px){
  .ag-theme-quartz.chatbarclose{
    min-width: 1310px !important;
  }
  .adddocuments_section.docchatbarclosed.datasourcebar-close .ag-theme-quartz{
    min-width:1590px !important ;
  }
  .ag-theme-quartz.chatbaropen {
    min-width: 1123px !important;
}
.adddocuments_section.chatbaropen.datasourcebar-close.sourcedocs-close .ag-theme-quartz{
  min-width: 1367px!important;
}
.hideview-docs{
  margin-right: 5.5%;
}
.adddocuments_section.chatbaropen .Frame123{
  width:97%;
}
.exploredata-open button.motif-button.motif-button-primary.motif-button-small.explore-data-container-sendimage{
  right: 1rem;
}
.exploredata-close.exploreDataContainer.datasourcebar-close.sourcedocs-close button.motif-button.motif-button-primary.motif-button-small.explore-data-container-sendimage{
  right: 0.2rem !important;
}
}
@media screen and (min-width: 1750px) and (max-width:1799px){
  .ag-theme-quartz.chatbarclose {
    min-width: 1355px !important;
}
.ag-theme-quartz.chatbaropen {
  min-width: 1165px !important;
}
}
@media screen and (min-width: 1800px){
.ag-theme-quartz.chatbaropen{
min-width: 1230px !important;
}
.ag-theme-quartz.chatbarclose{
  min-width: 1400px !important;
}
.adddocuments_section.docchatbarclosed.datasourcebar-close .Frame123{
  width: 98%;
}
.adddocuments_section.docchatbarclosed.datasourcebar-close .ag-theme-quartz{
  min-width: 1710px !important;
}
.adddocuments_section.chatbaropen.datasourcebar-close.sourcedocs-close .ag-theme-quartz{
  min-width: 1477px!important;
}
.exploredata-open .explore-data-container.hidedocuments .explore-data-container-prompt-question-container-child, .exploredata-open .explore-data-container.visibledocuments .explore-data-container-prompt-question-container-child{
  right:30px;
}
.exploredata-close.datasourcebar-close.sourcedocs-close .explore-data-container-prompt-question-container-child{
  right: 22px;
}
}
@media screen and (min-width: 1900px){
  .adddocuments_section.docchatbarclosed, .exploredata-close.exploreDataContainer{
    margin-left:70px;
  }
  .exploredata-open .explore-data-container.hidedocuments .explore-data-container-prompt-question-container-child, .exploredata-open .explore-data-container.visibledocuments .explore-data-container-prompt-question-container-child{
    right:30px;
  }
  .ag-theme-quartz.chatbarclose {
    min-width: 1472px !important;
  }
  .explore-data-container-with-quest{
    width: 100%;
  
  }
  .ag-theme-quartz.chatbaropen{
    min-width: 1452px !important;
  
  }
  .adddocuments_section.chatbaropen.datasourcebar-open .ag-theme-quartz.chatbaropen{
    min-width: 1320px !important;
    }
  
  .adddocuments_section.chatbaropen.datasourcebar-close.sourcedocs-close .ag-theme-quartz{
    min-width: 1702px!important;
  }
  .adddocuments_section.chatbaropen.datasourcebar-close.sourcedocs-close .ag-theme-quartz{
    min-width: 1572px!important;
  }
  /* .adddocuments_section.chatbaropen.datasourcebar-close.sourcedocs-close .ag-theme-quartz{
    min-width: 1686px!important;
  } */
  .exploredata-open.exploreDataContainer.datasourcebar-close.sourcedocs-close .explore-data-container-prompt-question-container-child{
    right: 38px;
  
  }
  .adddocuments_section.docchatbarclosed.datasourcebar-close .ag-theme-quartz{
    min-width:1790px!important;
  }
}
@media screen and (min-width: 1920px){
 .ag-theme-quartz.chatbarclose{
  min-width: 1484px!important;
 }
 .adddocuments_section.docchatbarclosed.datasourcebar-close .ag-theme-quartz{
  min-width:1808px !important;
 }
}
@media screen and (min-width:2700px){
  .adddocuments_section.chatbaropen.datasourcebar-close.sourcedocs-close .ag-theme-quartz{
    width: 2380px!important;
  }
}
@media screen and (max-width: 1366px){

  .exploredata-open .explore-data-container-promptbox-container {
    width: 100% !important;
    /* left:75px!important; */
    position: relative;
    height: auto;
  }
  .exploredata-open .explore-prompt-question-response-container-child{
    width: 94%!important;
  }
  .ag-theme-quartz.chatbaropen,.ag-theme-quartz.chatbarclose{
    height: 320px!important;
  }
  .exploredata-open .explore-data-container-promptarea-container{
    width:100%;
  }
  .exploredata-open .explore-data-container-prompt-question-container{
    width: 100%;
    left:0;
  }
 
 
}


@media screen and (max-width: 1360px) {
  .exploredata-open button.motif-button.motif-button-primary.motif-button-small.explore-data-container-sendimage{
    right: 3.8rem;
   }
   .ag-theme-quartz.chatbaropen,.ag-theme-quartz.chatbarclose{
    height: 300px!important;
   }
   .exploredata-open .explore-prompt-question-response-container-child{
    width: 93%!important;
   }
  .exploreDataContainer, .adddocuments_section{
    margin-left:42px;
  }
  .explore-data-container.hidedocuments{
    min-width: 700px;
  }
  .exploredata-open .explore-data-container-promptbox-container {
    width: 100% !important;
    position: relative;
    height: auto;
  }
  .exploredata-open .explore-data-container-child{
    width: 94%!important;
    left:0!important;
  }
  .exploredata-open.datasourcebar-close.sourcedocs-open .explore-data-container-child{
    width: 94.5%;
  }
  .exploredata-open.datasourcebar-open.sourcedocs-close .explore-data-container-child{
    width: 95%!important;
  }

  .exploredata-open.datasourcebar-close.sourcedocs-close .explore-data-container-child{
    width: 96%!important;
  }

  .explore-data-right-panel{
    width: 280px;
  }

}

@media screen and (max-width: 1149px) {
  .explore-data-container.hidedocuments{
    min-width: 700px;
  }

  
}
@media screen and (min-width:1360px) and (max-width: 1366px){
  
  .exploredata-open .explore-data-container-prompt-question-container {
    width: 100% !important;
    left: 0!important;
}
.exploredata-open .explore-data-container-prompt-question-container .motif-form-field.motif-form-field-text-input,.exploredata-open .explore-data-container-prompt-question-container .motif-form-field.explore-field-text-area-input {
  width: 97.5%!important;
}
.exploredata-close .explore-data-container-child{
  height: 500px;
  width:93%;
}
.exploredata-close.datasourcebar-open.sourcedocs-close .explore-data-container-child{
  width: 96%;
}
.exploredata-open .explore-data-container.hidedocuments .explore-data-container-prompt-question-container-child, .exploredata-open .explore-data-container.visibledocuments .explore-data-container-prompt-question-container-child{
  right: 24px;
}
.adddocuments_section.docchatbarclosed.datasourcebar-close .ag-theme-quartz{
  min-width: 1260px!important;
}
}

@media screen and (min-width:1279px) and (max-width: 1360px){
  .exploredata-close .explore-data-container-promptarea-container, .exploredata-open .explore-data-container-prompt-question-container{
    width:100%!important;
    left:1!important;
  }
  .exploredata-open .explore-data-container.hidedocuments .explore-data-container-prompt-question-container-child, .exploredata-open .explore-data-container.visibledocuments .explore-data-container-prompt-question-container-child{
    right: 20px;
  }
.explore-data-container-prompt-question-container .motif-form-field.motif-form-field-text-input,.explore-data-container-prompt-question-container .motif-form-field.explore-field-text-area-input {
  width: 93%!important;
}
 .explore-data-container-prompt-question-container .motif-form-field.motif-form-field-text-input,.explore-data-container-prompt-question-container .motif-form-field.explore-field-text-area-input{
  width: 98%!important;

}
.exploreDataContainer.datasourcebar-close.sourcedocs-open .explore-data-container-prompt-question-container .motif-form-field.explore-field-text-area-input{
  width: 99%!important;
}
.exploredata-open .explore-data-container-promptarea-container{
  left:0;
}
.exploredata-open.exploreDataContainer.datasourcebar-close.sourcedocs-close .explore-data-container-prompt-question-container-child{
  right: 25px;
}
}
@media screen and (min-width: 1348px) and (max-width: 1358px){
  .exploredata-open.exploreDataContainer.datasourcebar-close.sourcedocs-open button.motif-button.motif-button-primary.motif-button-small.explore-data-container-sendimage{
    right:-0.5rem!important;
  }
  .exploredata-close.exploreDataContainer.datasourcebar-open.sourcedocs-close button.motif-button.motif-button-primary.motif-button-small.explore-data-container-sendimage{
    right:0.8rem!important;
  }
  .exploredata-close.exploreDataContainer.datasourcebar-close.sourcedocs-close button.motif-button.motif-button-primary.motif-button-small.explore-data-container-sendimage{
    right: 1rem !important;
  }
}
@media screen and (max-width: 1280px) {
  .exploredata-open .explore-data-container.hidedocuments .explore-data-container-prompt-question-container-child, .exploredata-open .explore-data-container.visibledocuments .explore-data-container-prompt-question-container-child {
    right: 20px;
}
.exploredata-open.exploreDataContainer.datasourcebar-close.sourcedocs-close .explore-data-container-prompt-question-container-child{
  right: 20px!important;
  margin-top:10px;
}
}
.manage-docs-button,.view-hide-docs{
  font-family: EYInterstate!important;
  font-size: 16px!important;
  font-weight: 400!important;
  line-height: 24px!important;
  letter-spacing: 0em!important;
  text-align: left!important;
  background-color :#F3F3F5!important;
  }
  .view-hide-docs{
    background-color :#2E2E38!important;
    color:#FFFFFF!important;
    }
  .explore-data-container-prompt-question-container .motif-input-component input{
  font-family: EYInterstate;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  }
  .explore-data-container .motif-input-component .motif-input , .explore-data-container .motif-input-component {
    color: #2E2E38!important;
  }
  .explore-data-container .motif-input-component .motif-input:-webkit-autofill {
    -webkit-text-fill-color: #2E2E38 !important; 
    -webkit-box-shadow:none!important; 
  }

  .explore-data-container .motif-input-component .motif-input:-internal-autofill-selected {
    background-color: none!important;
    background: none!important;
    appearance:none!important;
  }
  button.motif-button.motif-button-primary.motif-button-small.ActivePublic{
    cursor: pointer!important;
  }
  button.motif-button.motif-button-primary.motif-button-small[disabled] {
    cursor: not-allowed!important;
}
.document-not-available {
  color: var(--Neutrals-Light-theme-500, #9897a6);
}
.noaction-document-not-available {
  pointer-events: none;
}
.breadcrumbOpen {
  margin-left: -21px;
  margin-top: -18px;
}
.breadcrumb {
  margin-left: -20px;
  margin-top: -18px;
}
.manage-docs,.explore-data-text{
  margin-top:5px;
}
@media screen and (max-width:1367px){
  .explore-data-container-image-container{
    margin-right:60px;
  }
}
.inputcontainer_60{
height: 60px;
padding: 12px 36px 8px 18px;
padding-right: 3.5rem!important;
width: 100%;
}
.inputcontainer_44{
  height: 44px;
  padding: 12px 36px 8px 18px;
  padding-right: 3.5rem!important;
  width: 100%;
  }
  .explore-data-container-prompt-question-container-child.buttonimage_60{
    margin-top: 18px;
  }
  .explore-field-text-area-input #question:focus-visible {
    color: #2e2e3c;
    outline: .5px auto #2e2e3c;
  }
  .explore-field-text-area-input #question:focus-visible {
    border: 2px solid #c3c3cb;
    border-radius: .25rem;
    outline: none;
}


.explore-field-text-area-input #question {
  background: #fff;
  border: 1px solid #c3c3cb;
  border: 1px solid  #c3c3cb;
  border-radius: .25rem;
  color: #2e2e3c;
  font-family: EYInterstate;
  overflow: hidden;
  padding: 12px 36px 8px 18px;
  resize: none;
}

.explore-field-text-area-input{
  margin-bottom:0.1rem;
}
.explore-data-container-response-note>div {
  height: auto;
}

.cursor-disabled {
  cursor: not-allowed !important;
}
.cursor-enabled {
  cursor: pointer !important;
}
.copy-text {
  margin-left: -15px;
  color: #737379;
}