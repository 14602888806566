@import '../../../styles/variables.module.scss';
@import '../../../styles/_mixins.scss';
@mixin custom-scrollbar-theme {
  &::-webkit-scrollbar {
    width: $custom-scrollbar-width;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-40;
  }

  &::-webkit-scrollbar-button {
    height: 3px;
  }
}
.chatreply {
  @include justify-start;
  padding: toRem(4px) toRem(8px) toRem(8px) toRem(8px);
  margin: toRem(4px) toRem(4px);

  &__img-container {
    @include start-flex;
    margin-right: toRem(16px);
  }

  &__img-container-dark {
    @include start-flex;
    border-radius: toRem(2px);
    height: toRem(32px);
    padding: toRem(2px);
    background: $white;
    margin-right: toRem(16px);
  }
  &__engagement-container {
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 6px;
    }
  }
  &__resp-box {
    @include break-word;
    @include start;
    border-radius: 4px;
    font-size: toRem(14px);
    color: var(--Neutrals-Light-theme-900, #2e2e38);
    text-align: justify;
    width: 95%;
    flex-direction: column;
    border: 1px solid var(--Neutrals-Light-theme-200, #d7d7dc);
    background: #F3F3F5;
    margin-bottom: 10px;
    position: relative;
    &__resp {
      position: relative;
      padding: toRem(10px) toRem(15px);
      white-space: pre-wrap;
      width: 100%;
      font-family: EYInterstate;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      padding-bottom: 2px;
      color: var(--Neutrals-Light-theme-900, #2e2e38);
      & table.content-table {
        margin-top: 20px;
        border-collapse: collapse;
        border: 1px solid #747480;
        width: 100%;
        table-layout: auto;
        // background-color: #585860;

        & th {
          padding: 20px 10px 5px 20px;
          width: 40px;
          text-transform: capitalize;
          min-width: 100px;
        }

        tr {
          border-bottom: 1px solid #747480;
        }

        th,
        td {
          border-right: 1px solid #747480;
        }

        td {
          padding: 10px 20px;
          text-align: left;
        }

        td.disclaimer {
          font-size: 11px;
        }
      }
    }
    .disclaimer {
      font-size: 0.8125rem;
      padding: 12px 15px 0px;
      border-top: 1px solid #383843;
      width: 100%;
      display: flex;
      margin-top: 1%;
    }
    &__footer {
      display: block !important;
      font-size: 12px;
      line-height: 20px;
      display: flex;
      padding: 0px 15px 9px 15px;
      width: 100%;

      &__source-box {
        white-space: nowrap;
        height: 25px;
        display: flex;
        align-items: center;
        position: relative;
        & p {
          color: var(--Neutrals-Light-theme-700, #656579);
          font-feature-settings:
            'clig' off,
            'liga' off;
          font-family: EYInterstate;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 166.667% */
        }
      }
      &__survey-moreitems {
        height: 20vh;
      }
      &__survey-lessitems {
        height: 10vh;
      }
      &__links-box {
        // margin-left: 10px;
        display: flex;
        gap: 10px;
        align-items: center;
        flex-wrap: wrap;
        overflow: hidden;
        margin-bottom: 2px;
        &__source-link {
          align-items: center;
          padding: 5px 10px;
          font-size: 12px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          color: var(--primary-ey-black-1-a-1-a-24, #1a1a24);
          font-feature-settings:
            'clig' off,
            'liga' off;
          font-family: EYInterstate;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 166.667% */
          text-decoration-line: underline;
          text-decoration-color: var(--Neutrals-Light-theme-700, #656579);
        }
      }
      &__surveys-links-box {
        gap: 3px;
      }
      &__links-box.slider {
        margin-left: 0;
        margin-top: 5px;
      }
    }

    &__footer.slider {
      flex-direction: column;
    }
  }

  &__resp-box.sidebar-open {
    width: 88%;
  }

  &__suggestions_container {
    // display: flex;
    position: relative;
    gap: 10px;
    left: 6%;
    width: 90%;
  }

  &__loader-container {
    @include left-flex;
    max-width: 80%;
    border-radius: 4px;
  }

  &__list {
    margin: 0;
  }

  &__list-row {
    list-style-type: square;
  }
  &__no-links {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    color: var(--primary-ey-white-ffffff, #fff);
    padding-right: 10px;
  }
  &__survey_no-links {
    color: var(--primary-ey-black-1-a-1-a-24, #1a1a24);
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: EYInterstate;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration: none;
  }
  &__no-links-gap {
    margin-left: 10px;
    display: flex;
    // gap: 10px;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
  }
  .regenerate-container {
    position: absolute;
    width: 10%;
    right:0;
    display: flex;
    flex-direction: row-reverse;
    bottom: 0%;
  }
  &__regenerate {
    position: relative;
    left: -20px;
    top: 16px;
    display: flex;
    cursor: pointer;
  }
  &__copy {
    position: relative;
    left: -20px;
    top: 16px;
    display: flex;
    cursor: pointer;
    margin-right: 10%;
  }
}

.chatreply.sidebar-open {
  margin: 0;
  padding: toRem(4px) toRem(8px) toRem(8px) toRem(8px);
}

.table-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
}

.table-wrapper::-webkit-scrollbar {
  height: 5px;
}

.table-wrapper::-webkit-scrollbar-thumb {
  background: #c4cdd5;
  border-radius: 10px;
}
.chunk-loader {
  background-size: contain;
  background-repeat: no-repeat;
  height: 50px;
  width: 28px;
  padding-right: 6px;
  margin-bottom: -27px;
  display: flex;
  margin-left: 2px;
}
.link-container {
  border: 1px solid var(--primary-ey-black-1-a-1-a-24, #1a1a24);
  border-radius: 18px;
  &.surveychip {
    .motif-chip-button {
      color: var(--primary-ey-black-1-a-1-a-24, #1a1a24) !important;
      font-feature-settings:
        'clig' off,
        'liga' off;
      font-family: EYInterstate;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      background: transparent !important;
    }
  }
}
.ext-link-chip {
  color: var(--primary-ey-black-1-a-1-a-24, #1a1a24);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: EYInterstate;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  text-decoration-line: underline;
}
.suggestions-text {
  position: relative;
  gap: 10px;
  color: black;
  font-size: 14px;
  margin: 0% 1%;
}
.chip-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 1%;
}

.suggestion-img-container {
  display: flex;
  align-items: center;
}

.motif-chip .motif-chip-button {
  background-color: #d2e1fa !important;
  color: #0a558e !important ;
}

.toast-container-public {
  position: absolute;
  top: -50px;
  bottom: auto;
  width: 30%;
  right: 10%;
  left: auto;
  z-index: 9999999999;
}

.toast-container-public-error {
  position: absolute;
  top: -50px;
  bottom: auto;
  width: 50%;
  right: 10%;
  left: auto;
  z-index: 9999999999;
}

.toast-container-sector {
  position: absolute;
  top: -167px;
  bottom: auto;
  width: 30%;
  right: 10%;
  left: auto;
  z-index: 9999999999;
}

.open-ai-icon {
  height: 39px;
  min-width: 2.6rem;
}

.span-fontweight {
  font-weight: 600;
  cursor: pointer;
}

.stop-generatio-label {
  font-size: 14px !important;
  font-style: italic !important;
  color: #656579 !important;
}

.handleDnfHidden {
  display: none;
}

.handleDnfvisible {
  display: block; /* or any other display style you want */
}

.chat-reply-copy-response-icon {
  display: flex;
  position: absolute;
  right: 0;
  cursor: pointer;
}


.chat-reply-copy-response-icon>button {
  background: transparent;
  border: none;
}

.copy-button {
  display: flex;
  justify-content: flex-start;
  min-width: 2.2rem !important;
}
.chat-copy-text {
  margin-left: -15px;
  margin-right: 5px;
}

.spacer {
  margin-bottom: 2%;
}

.chat-copy-button-div {
  margin-top:4px;
  min-height:20px;
}
