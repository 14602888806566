@font-face {
    font-family: 'EYInterstate';
    src: url('../fonts/EYInterstate/EYInterstate-Regular.ttf');
}

@font-face {
    font-family: 'EYInterstateBold';
    src: url('../fonts/EYInterstate/EYInterstate-Bold.ttf');
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/NotoSans-Regular.ttf');
}

@font-face {
    font-family: 'NotoSansBold';
    src: url('../fonts/NotoSans/NotoSans-Bold.ttf');
}

@font-face {
    font-family: 'NotoSansItalic';
    src: url('../fonts/NotoSans/NotoSans-Italic.ttf');
}
@font-face {
    font-family: 'agGridMaterial';
    src: url('../fonts/agGridMaterial.woff');
}
@font-face {
    font-family: 'EYInterstateLight';
    src: url('../fonts/EYInterstate/EYInterstate-Light.ttf');
}
body {
    margin: 0;
    font-family: 'EYInterstate', 'Segoe UI', sans-serif !important;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #2e2e38;
    color: #ffffff;
}

.bold {
    font-family: 'EYInterstateBold', 'Segoe UI', sans-serif;
    font-weight: 600;
    font-style: normal;
}

* {
    box-sizing: border-box;
}

input[type='text']::-ms-clear {
    display: none;
}

input:-webkit-autofill {
    -webkit-text-fill-color: #fff !important;
    -webkit-box-shadow: 0 0 0 30px #1a1a24 inset !important;
}

*:focus {
    outline: none;
}
