@use '@ey-xd/motif-react/styles.scss';
@import 'style.scss';
@import "../public/assets/styles/main.scss";

body {
  margin: 0;
  font-family: 'EYInterstate';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'EYInterstate';
}

.motif-checkbox-label{
  color:black !important;
}

.padding-bottom-try-questions {
  padding-top: 5%!important;
}
