@import 'variables.module.scss';
@import 'button.scss';
 
 /* Hide the default checkbox */
 input[type="checkbox"] {
    display: none;
  }
  
  /* Create a new box in place of the default checkbox */
  input[type="checkbox"] + label {
    position: relative;
    padding-left: 21px;
    cursor: pointer;
  }
  
  input[type="checkbox"] + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 1px;
    width: 12px;
    height: 12px;
    border: 1px solid #4D4D5C;
    background: #fff;
  }
  
  /* Style for checked state */
  input[type="checkbox"]:checked + label:before {
    background: #fff;
  }
  
  /* Create a custom tick */
  input[type="checkbox"]:checked + label:after {
    content: "";
    position: absolute;
    left: 4px;
    top: 1px;
    width: 4px;
    height: 9px;
    border: solid #000;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  input[type="checkbox"]:disabled + label:before {
    background: #ccc;
    border-color: #ccc;
    cursor: not-allowed;
  }
  
  input[type="checkbox"]:disabled + label:after {
    border-color: #fff;
    cursor: not-allowed;
  }

//    /* Hide the default checkbox */
//  input[type="radio"] {
//     display: none;
//   }
  
//   /* Create a new box in place of the default checkbox */
//   input[type="radio"] + label {
//     position: relative;
//     padding-left: 20px;
//     cursor: pointer;
//   }
//    input[type="radio"].radio-header + label {
//     position: relative;
//     padding-left: 20px;
//     cursor: pointer;
//   }

//   input[type="radio"] + label:before {
//     content: "";
//     position: absolute;
//     left: 0px;
//     top: 3px;
//     width: 14px;
//     height: 14px;
//     border: 1px solid #fff;
//     background: #fff;
//     border-radius: 50%;
//   }
  
//   /* Style for checked state */
//   input[type="radio"]:checked + label:before {
//     background: #fff;
//   }
  
//   /* Create a custom tick */
//   input[type="radio"]:checked + label:after {
//     content: "";
//     position: absolute;
//     left: 1px;
//     top: 4px;
//     width: 9px;
//     height: 9px;
//     background: #fff;
//     border-radius: 50%;
//     border: 2px solid #656579;
//     box-shadow: 0 0 5px rgba(101, 101, 121, 0.3);
// }
  
//   input[type="radio"]:disabled + label:before {
//     background: #ccc;
//     border-color: #ccc;
//   }
  
//   input[type="radio"]:disabled + label:after {
//     border-color: #ccc;
//   }
  