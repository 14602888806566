.tree-node {
  margin-left: toRem(21px);
  &__leaf {
    margin-left: toRem(31px);
  }

  #{&} {
    &__checkbox {
      word-break: break-word;
      margin-top: toRem(3px);
    }

    &--check-disabled {
      cursor: default;
      .motif-checkbox-label {
        color: grey;
      }
    }
  }
  .motif-tree-node-button {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
  }
   .motif-tree-node-content {
    align-items: flex-start;
   }
  .motif-checkbox-label {
    color: #2e2e38 !important;
    font-family: EYInterstate !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px;
    // toggle icon
    // text-align: left;
    // position: relative;
    // left: 38px;
  }

  .motif-checkbox-checked {
    .motif-checkbox-custom {
      svg {
        color: rgb(250, 249, 249) !important;
        // width: 100%;
        // height: auto;
        // background-color: green;
      }

      &:active {
        border-color: var(--checkbox-checked-focus--border-color);
        border-width: var(--checkbox-checked-focus--border-width);
        border-style: var(--checkbox-checked-focus--border-style);
        box-shadow: var(--checkbox-checked-focus--box-shadow);
        background: var(--checkbox-checked-focus--bg-color);
        vertical-align:middle;
      }
  
      &:hover {
        background: var(--checkbox-checked-hover--bg-color);
        vertical-align:middle;
      }
    }
  }

  .motif-checkbox {
    &:hover {
      &:not(.motif-checkbox-checked) {
        .motif-checkbox-custom {
          border-width: var(--checkbox-custom-hover--border-width);
        }
      }

      &:not(.motif-checkbox-disabled) {
        .motif-checkbox-label {
          text-decoration: none;
        }
      }
    }
  }
}

/* #region Custom Tree Node CSS */
.motif-tree-nodes:empty {
  display: none;
}
.with-out-child .motif-tree-node-content .motif-icon-button {
  pointer-events: none;
  opacity: 0.5; 
}
.motif-tree-node .motif-tree-node-button svg{
  transform: rotate(90deg);
}
.motif-tree-node-visible .motif-tree-node-button svg{
  transform: rotate(270deg) !important;
}
/* #endregion Custom Tree Node CSS */