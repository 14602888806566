@import 'variables.module.scss';
@import 'button.scss';
@import 'checkbox.scss';

.tippy-popper a {
  color: #2E2E38;
  text-decoration: none;
}


.custom-theme,
.vis-tooltip {
  background: #FFFFFF !important;
  width: 55%;
  font-size: 1rem !important;
  padding: 6px 10px;
  border-radius: 4px;
  word-wrap: break-word;
  position: relative;
  left: 10%!important;
  top:-32px!important;
}

.chatHistory-tooltip-container, .workspace-tooltip-container{
  background: #FFFFFF !important;
  width: 48%;
  font-size: 12px !important;
  border-radius: 4px;
  word-wrap: break-word;
  left: 24% !important;
  top: 9px !important;
}

.confidential-tooltip-container {
  background: #FFFFFF !important;
  width: 100%;
  font-size: 12px !important;
  border-radius: 4px;
  // border: 1px solid #1A1A24 !important;
  word-wrap: break-word;
  left: 0% !important;
  top: -5px !important;
}

.workspace-tooltip-container{
  left: 0 !important;
  width: 56%;
}

.tab-custom-class{
  position : relative;
  top:10px;
}
.ie-tooltip {
  background-color: #FFFFFF !important;
  width: 100% !important;
  font-size: 1rem !important;
  padding: 6px 10px;
  border-radius: 0;
  word-wrap: break-word;
  position: relative;
  left: 8% !important;
  top: -32px !important;
}

.public-tooltip-placement {
  box-shadow: 0px 8px 16px 0px #1A1A2429;
  background-color: #FFFFFF !important;
  border: 1px solid #E6E6E9 !important;
  width: 50% !important;
  padding: 6px 10px;
  border-radius: 0;
  word-wrap: break-word;
  position: relative;
  left: -15% !important;
  top: -32px !important;
  font-family: EYInterstate !important;
  font-size: 12px !important;
  font-weight: 300 !important;
  line-height: 18px !important;
  text-align: left !important;
}

.project-tooltip-placement {
  box-shadow: 0px 8px 16px 0px #1A1A2429;
  background-color: #FFFFFF !important;
  border: 1px solid #E6E6E9 !important;
  width: 50% !important;
  padding: 6px 10px;
  border-radius: 4px;
  word-wrap: break-word;
  position: relative;
  left: 23% !important;
  top: -10px !important;
  font-family: EYInterstate !important;
  font-size: 12px !important;
  font-weight: 300 !important;
  line-height: 18px !important;
  text-align: left !important;
}
.eyip-tooltip-placement {
  box-shadow: 0px 8px 16px 0px #1A1A2429;
  background-color: #FFFFFF !important;
  border: 1px solid #E6E6E9 !important;
  width: 50% !important;
  padding: 6px 10px;
  border-radius: 0;
  word-wrap: break-word;
  position: relative;
  left: -17% !important;
  top: -32px !important;
  font-family: EYInterstate !important;
  font-size: 12px !important;
  font-weight: 300 !important;
  line-height: 18px !important;
  text-align: left !important;
}

.eyexpert-tooltip-placement {
  box-shadow: 0px 8px 16px 0px #1A1A2429;
  background-color: #FFFFFF !important;
  border: 1px solid #E6E6E9 !important;
  width: 50% !important;
  padding: 6px 10px;
  border-radius: 0;
  word-wrap: break-word;
  position: relative;
  left: -15% !important;
  top: -32px !important;
  font-family: EYInterstate !important;
  font-size: 12px !important;
  font-weight: 300 !important;
  line-height: 18px !important;
  text-align: left !important;
}

.tooltip-placement {
  top: 20px!important;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: rgba(145, 158, 171, 0.6);
}
.divider {
  width: 100% ;
  border-bottom: 1px solid #E7E7EA;
}
.divider-light {
  width: 100% ;
  border-bottom: 1px solid #E7E7EA;
}
.text-center {
  text-align: center;
}
/* Common */
.break-word {
  word-wrap: break-word;
  /* must be applied globally for now, because of dynamic measuring in company list */
  .entity {
    &:not(:last-child) {
      margin-right: 10px;
    }
    &:not(:first-child)::before {
      content: ',';
      position: absolute;
      left: -10px;
    }
  }

  &:not(:first-child)::before {
    content: ',';
    position: absolute;
    left: -10px;
  }
}
.bg-cover {
  width: 100%;
  height: 100%;
  background-color: $grey-15;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}
.ey-interstate {
  font-family: $font-interstate;
}
/* Icons */
[class$='-icon'] {
  font-style: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
.header-icon {
  position: absolute;
  right: 15px;
}
$color1: $grey-3A;
$color2: $alternateblack;
$color3: $yellow;
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	background: #1A1A24;
  color: $white;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.page-layout {
  padding: 21px;
  padding-top: 0px;
}
.bvKqMl,
#root,
.App {
  height: 100%;
}
#root {
  position: relative;
  z-index: 100;
}
.container {
  max-width: 960px;
  margin: 0 auto;
  display: flex;
}
.col-1 {
  flex-basis: 8.333%;
}
.col-2 {
  flex-basis: 16.666%;
}
.col-3 {
  flex-basis: 25%;
}
.col-4 {
  flex-basis: 38.333%;
}
.col-5 {
  flex-basis: 41.666%;
}
.col-6 {
  flex-basis: 50%;
}
.col-7 {
  flex-basis: 58%;
}

.footer-col-8{
  flex-basis: 60%;
}
.footer-col-4{
  flex-basis: 40%;
}
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $color2;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: $color1;
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $color1;
}
.ie-react-model {
  border: 0 !important;
}
.ie-react-model:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}
.ie-modal-body {
  padding: 16px !important;
}
.ie-row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
}
.ReactModal__Overlay {
  z-index: 999 !important;
}
._pendo-badge {
  z-index: 900 !important;
}
.ie-model-1 {
  .submit-modal {
    .yellow-btn {
      margin-left: 1rem;
      border: 1px solid;
    }
  }
}
.active .center-wrapper {
  color: $white;
}
.ReactModal__Overlay--after-open {
  background-color: rgba(26, 26, 36, 0.8) !important;
}
.ReactModal__Body--open {
  overflow-y: hidden;
}

.button-right {
  margin-left: 15px;
}
#mypopup {
  width: 400px;
  padding: 20px;
  font-family: Arial, sans-serif;
  font-size: 10pt;
  background-color: white;
  border-radius: 6px;
  position: absolute;
  display: none;
}
#mypopup::before {
  content: '';
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  background-color: white;
  position: absolute;
  left: -6px;
  top: 68px;
}

.center-wrapper {
  position: absolute;
  text-align: center;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  // margin-top: 1rem;
}

.global-loder {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99999999;
}

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto; 
}

.load {
  text-align: center;
}

html {
  overflow-x: hidden;
}
@for $i from 5 through 99 {
  .mt-#{$i} {
      margin-top: #{$i}px !important;
    }
    .ml-#{$i} {
      margin-left: #{$i}px !important;
    }
}

.right_sidebar{
  ::-webkit-scrollbar {
    width: 7px !important ;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #F6F6FA;
    border-radius: 10px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #C4C4CD;
    border-radius: 10px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #C4C4CD;
  }
  }

  
.chatTooltip{
  .tippy-popper a {
    color: #2E2E38;
    text-decoration: none;
  }
  .tippy-popper  {
    color: #2E2E38;
    position:relative;
    top:10px!important;
    text-decoration: none;
  }
}
