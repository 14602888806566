#closepopupmodal {
    width: 540px !important;  
    padding: 24px;
    height: 336px;      
}

#text{
    text-align:justify;
    color: #000;
}
