@mixin flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @mixin flex-column {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  @mixin flex-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  @mixin box-shadow1 {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
  @mixin basic-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  @mixin center-flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @mixin start-flex {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  
  @mixin left-flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  @mixin flex-baseline-default {
    display: flex;
    align-items: baseline;
  }
  
  @mixin flex-baseline-between {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
  
  @mixin justify-start {
    display: flex;
    justify-content: flex-start;
  }
  
  @mixin justify-end {
    display: flex;
    justify-content: flex-end;
  }
  @mixin start {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  @mixin end {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }
  
  @mixin start-space {
    display: flex;
    justify-content: space-between;
  }
  
  @mixin flex-end {
    display: flex;
    align-items: flex-end;
  }
  
  @mixin end-flex {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  @mixin inline-center-flex {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  @mixin center {
    display: flex;
    align-items: center;
  }
  @mixin common-transition {
    transition: all 0.3s ease;
  }
  
  @mixin opacity-transition {
    transition: opacity 0.25s ease;
  }
  
  @mixin no-space {
    margin: 0;
    padding: 0;
  }
  
  @mixin no-padding {
    padding: 0;
  }
  
  @mixin no-margin {
    margin: 0;
  }
  @mixin no-outline {
    outline: none;
  }
  @mixin input-text {
    border: 0.06rem solid $grey-300;
    @include no-outline;
    padding: 0.5rem;
    width: 100%;
  }
  @mixin basic-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  @mixin center-flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @mixin start-flex {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  
  @mixin left-flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  @mixin flex-baseline-default {
    display: flex;
    align-items: baseline;
  }
  
  @mixin flex-baseline-between {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
  
  @mixin start {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  @mixin end {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }
  
  @mixin start-space {
    display: flex;
    justify-content: space-between;
  }
  
  @mixin flex-end {
    display: flex;
    align-items: flex-end;
  }
  
  @mixin end-flex {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  @mixin inline-center-flex {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  @mixin center {
    display: flex;
    align-items: center;
  }
  @function toRem($size) {
    $remSize: ($size/ 16px);
    @return $remSize * 1rem;
  }
  @mixin text-center {
    text-align: center;
  }
  @mixin box-shadow2 {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
  @mixin break-word {
    word-break: normal;
    overflow-wrap: anywhere;
  }