.table {
  &__wrapper-sector {
    padding-top: 16px;
  }
  .wrapper-sector {
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .wrapper-sector::-webkit-scrollbar {
    height: 5px;
  }

  .wrapper-sector::-webkit-scrollbar-thumb {
    background: #c4cdd5;
    border-radius: 10px;
  }
  &__collapse {
    span {
      cursor: pointer;
      padding: 0px 10px;
      font-weight: bold;
      font-size: 18px;
    }
    padding: 10px 0;
    background-color: #c4c4cd;
    border: 1px solid #747480;
    color: #2e2e38;
  }
  &__content-table-sector {
    margin-top: 20px;
    border-spacing: 0;
    border: 1px solid #e1e1e6;
    width: 73%;
    table-layout: auto;
    background-color: #ffffff;
    border-radius: 4px;
    border-collapse: separate;
    &__footer {
      text-align: right;
    }
    .files-download-btn {
      cursor: pointer;
      text-decoration: underline;
      color: var(--specific-use-colors-default-text-link-155-cb-4, #155cb4);
    }
    .files-slide-download-btn {
      // cursor: pointer;
    }

    th {
      padding: 5px 20px;
      // width: 40px;
      // // text-transform: capitalize;
      min-width: 100px;
      width: 60%;
    }
    tr {
      background: #e1e1e6;
    }
    th {
      border-bottom: 1px solid #e1e1e6;
      border-right: 1px solid #e1e1e6;
    }
    td {
      border-bottom: 1px solid #e1e1e6;
      border-right: 1px solid #e1e1e6;
      background: var(--primary-white, #fff);
    }

    td {
      padding: 5px 20px;
      text-align: left;
      vertical-align: top;
    }

    td.disclaimer {
      font-size: 11px;
    }
    td {
      .btn-show {
        color: #23232f;
        background: transparent;
        border: none;
        text-decoration: underline;
        align-items: right;
        cursor: pointer;
      }
    }
    td {
      .table__author {
        text-decoration: underline;
        color: var(--specific-use-colors-default-text-link-155-cb-4, #155cb4);
        cursor: pointer;
        margin-right: 3px;
      }
    }
    td.table__footer {
      text-align: right;
    }
  }
}
