.surveyChatContainer {
  // margin-top: 36px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 26px;
  height: 107vh;
  @media screen and (min-width: 1400px) and (max-width: 1919px) {
    height: 100vh;
  }
  @media screen and (min-width: 1196px) and (max-width: 1300px) {
    height: 113vh;
  }
  @media screen and (min-width: 1920px) {
    height: 100vh;
  }

  &__sidebarClosed {
    flex-direction: column;
    position: relative;
    display: block;
    margin-left: 37px;
    height: 107vh;
    @media screen and (min-width: 1196px) and (max-width: 1300px) {
      height: 117vh;
    }
    @media screen and (min-width: 1400px) and (max-width: 1919px) {
      height: 100vh;
    }
    @media screen and (min-width: 1920px) {
      height: 100vh;
    }
  }
  &__scroll-container {
    max-height: 74%;
    @media screen and (min-width: 1517px) and (max-width: 1900px) {
      height: 58%;
    }
    overflow-y: auto;
    width: 95%;
    padding-bottom: 8px;
  }
  &__input-container {
    width: 99%;
  }
  &__breadcrumbOpen {
    margin-left: 40px;
    margin-top: 18px;
  }
  &__breadcrumb {
    margin-left: 42px;
    margin-top: 20px;
  }
  &__download-btn-box {
    position: absolute;
    margin-left: 50%;
    transform: translate(-50%, -50%);
  }
  &__download-text {
    color: black;
    font-family: EYInterstate;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    font-weight: 400;
    margin-left: 8px;
  }
  &__survey-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // margin-top: -0% !important
    margin: 18px 34px;
  }
  &__survey-button {
    height: 44px;
    display: flex;
    align-self: flex-end;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0));
    background: var(--Neutrals-Light-theme-50, #f3f3f5);
    color: black;
    padding: 18px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
  }
  &__toast-container-public {
    position: absolute;
    top: -77px;
    bottom: auto;
    width: 30%;
    right: 10%;
    left: auto;
    z-index: 9999999999;
  }
  .loader-position {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    margin: 0;
    text-align: center;
    flex-direction: column;

    .loader {
      border: 5px solid #f3f3f3;
      border-radius: 50%;
      border-top: 5px solid #2e2e38;
      width: 50px;
      height: 50px;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
    }

    /* Safari */
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  // .isSurveyDoc {
  //   margin: 18px 26px !important;
  // }
  // .isNotSurveyDoc {
  //   margin: 18px 34px !important;
  // }
  .scroll-height {
    min-height: 200px;
  }
  .stop-generation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
  }

  .stop-generation-button {
    width: 132px;
    height: 36px;
    background-color: #2e2e38;
    color: #ffffff;
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
  }
}
