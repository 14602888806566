@import '../../../styles/variables.module.scss';
@import '../../../styles/_mixins.scss';

.chatbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding: 0.25rem 0rem 0.5rem 0.1rem;
  margin: 0.25rem 2.3rem 0rem 3.1rem;  

  &__text-area-container {
    padding: 8px 0 0 0;
    width: 100%;
    color: #fff;
    position: relative;
    display: flex;
    align-items: center;
  }

  &__text-area {
    width: 95%;
    resize: none;
    overflow: hidden;
    padding: 12px 36px 8px 18px;
    background: #ffffff;
    color: #2e2e3c;
    font-family: EYInterstate;
    border-radius: 0.25rem;
    border: 1px solid var(--Neutrals-Light-theme-300, #c3c3cb);
  }
  .chatbox__text-area:focus-visible {
    outline: none;
    border-radius: 0.25rem;
    border: 2px solid var(--Neutrals-Light-theme-300, #c3c3cb);
  }
  &__text-area.sidebar-open {
    padding: 8px 36px 8px 8px;
  }

  &__text-area:focus-visible {
    outline: #2e2e3c auto 0.5px;
    color: #2e2e3c;
  }

  &__send-container {
    padding: 0px 0px 0px 8px;
  }

  &__label {
    color: #1a1a24;
    font-weight: bold;
    padding-left: 8px;
  }

  &__label-dark {
    color: #fff;
    font-weight: 500;
    padding-left: 8px;
  }
  &__sidebar-margin {
    margin: 0.25rem 2.3rem 0rem 3.1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    padding: 0.25rem 0rem 0.5rem 0.1rem;
  }
  &__chat-img {
    width: 20px;
    height: 20px;
    display: flex;
    position: absolute;

    padding-left: 14px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  &__send-icon {
    height: 52px;
    display: flex;
    padding: 6px 0.75rem 0px;
    justify-content: center;
    align-items: center;
  }

  &__char-limit {
    display: flex;
    color: var(--primary-grey-400747480, #747480);
    justify-content: flex-end;
    width: 94%;
    font-size: 12px;
    margin-top: 3px;
    font-size: 13px;
    font-weight: 400;
  }
  &__nosourceselected {
    display: flex;
    color: var(--primary-grey-400747480, #747480);
    justify-content: flex-end;
    width: 92%;
    font-size: 12px;
    margin-top: 3px;
    font-size: 13px;
    font-weight: 400;
  }
  &__warning {
    color: #c70117;
  }
  &__clearbtn-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &__download-message {
    font-size: toRem(13px);
    color: #fff;
    margin: 0;
    display: flex;
  }

  &__clearbtn-box {
    position: relative;
    top: -5px;
  }

  @media screen and (max-width: 1400px) {
    &__clearbtn-box {
      position: relative;
      top: -10px;
    }
  }

  @media screen and (min-width: 1360) and (max-width: 3000px) {
    &__clearbtn-box {
      position: relative;
      top: -15px;
    }
  }
}

.warning-message-input {
  color: red;
  font-size: 12px;
  width: 100%;
}

.number-validate-both-closed {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  position: relative;
  @media screen and (min-width: 1100px) and (max-width: 1300px) {
    width: 98%;
  }

}

.number-validate-sidebar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  position: relative;
  @media screen and (min-width: 1100px) and (max-width: 1300px) {
    width: 98%;
  }
}
.number-validate {
  display: flex;
  justify-content: space-between;
  width: 97%;
  font-size: 12px;
  font-weight: 400;
  position: relative;
  @media screen and (min-width: 1464px) and (max-width: 1517px) {
    width: 98%;
  }
  @media screen and (min-width: 1518px) and (max-width: 3000px) {
    width: 100%;
  }
}
.survey-number-validate {
  display: flex;
  justify-content: flex-end;
  width: 97%;
  font-size: 12px;
  font-weight: 400;
  position: relative;
  top: -44px;
}
.warning-length-input {
  width: 95%;
  // display: flex;
  color: #747480;
}
.warning {
  color: red;
  font-size: 12px;
  width: 100%;
}
.warning-black{
  color:  #2e2e38 
}
.cursor-disabled {
  cursor: not-allowed !important;
}
.cursor-enabled {
  cursor: pointer !important;
}
.textarea1 {
  height: 60px;
}
.textarea2 {
  height: 44px;
}
.query-text-length {
  text-align: right;
}
