.customdropdown {
  position: relative;
  width: 33%;
  .customdropdown_next{
    max-width: 100%;
  }
  &__options {
    width: 100%;
    background: var(--primary-ey-white-ffffff, #FFF);
    border-radius: 7px;
    position: absolute;
    z-index: 9999;
    left: 0;
    color: #000000;
    option {
      padding: 6px 16px;
      @media screen and (max-width: 1300px) {
        padding: 3.8px 16px;
      }
    }
  }
  customdropdown__options{
    width: 100%;
  }
  .customdropdown__options:has(.customdropdown__option-menu) { 
    border :1px solid rgb(204, 204, 204);
   }
  &__option-menu:hover {
    background-color: #e7e7ea;
  }
  &__option-menu:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
  &__footer {
    display: flex;
    justify-content: center;
    gap: 16px;
    padding: 16px;
  }
  .sidebar-open {
    width: 233px;
  }
  .sidebar-close {
    width: 286px;
  }
  .bg-selected {
    background: #e7e7ea;
  }
  .bg-unselected {
    background: transparent;
  }
}
.contentagewithradio__options{
width: 100% !important;
}
label.contentagewithradio-dropdown {
  padding: 8px 16px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  cursor: pointer;
  .customdropdown__option-menu{
    cursor: pointer;
  }
  .customdropdown__option-menu:checked {
    accent-color:#656579;
  }
}
.mrClass {
  width: 100% !important;
}
