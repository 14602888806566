@import '../../styles/variables.module.scss';
@import '../../styles/mixins';
@mixin custom-scrollbar-theme {
  &::-webkit-scrollbar {
    width: $custom-scrollbar-width;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-40;
  }

  &::-webkit-scrollbar-button {
    height: 3px;
  }
}
.expertsChatContainer {
  margin-top: 50px;
  position: relative;
  display: flex;
  margin-left: 36px;
  width: 96%;
  height: 70vh;
  @media screen and (min-width: 1000px) and (max-width: 1300px) {
    height: 70vh;
  }
  @media screen and (min-width: 1464px) and (max-width: 1705px) {
    height: 67vh;
  }
  @media screen and (min-width: 1706px) and (max-width: 1919px) {
    height: 63vh;
  }
  @media screen and (min-width: 1920px) {
    height: 67vh;
  }
  &__scroll-container {
    height: 99%;
    overflow-y: auto;
    width: 95%;
    padding-bottom: 8px;
    overflow-x: hidden;
  }
  &__input-container {
    /* position: absolute; */
    // width: 97%;
    display: flex;
    flex-direction: column;
  }
  &__download-btn-box {
    margin: auto;
    padding-bottom: 2%;
  }
  &__download-text {
    color: black;
    font-family: EYInterstate;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    font-weight: 400;
    margin-left: 8px;
  }
  &__container-with-chat {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 50px;
  }

  &__explore_btn {
    margin: 20px auto;
    display: flex;
    width: 17.8125rem;
    height: 4.8125rem;
    padding: 0.6875rem 2rem;
    justify-content: center;
    align-items: center;
    background: #ffe600;
    color: var(--grey-500, #2e2e38);
    text-align: center;
    font-family: EYInterstate;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.25rem;
    cursor: pointer;
    border: none;
  }

  &__header {
    padding-left: 8px;
    padding-top: 8px;
    text-align: start;
    font-weight: bold;
    color: #fff;
  }

  &__shadow-container {
    margin: 8px;
    border-radius: 4px;
    background: #ffff;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    justify-content: center;
    max-height: 320px;
    width: 90%;
  }
  &__scrollable-content {
    //padding-bottom: 100px;
    // overflow-y: scroll;
    // position: fixed;
  }
  &__shadow-container-dark {
    margin: 8px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    background: $alternateblack;
  }

  &__title {
    color: var(--primary-ey-white-ffffff, #fff);
    text-align: center;
    font-style: normal;
    font-weight: 500;
    line-height: toRem(58px);
    font-size: toRem(48px);
  }
  &__header-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 36px 46px 8px 4%;
    gap: 16px;
    max-height: 95px;
  }
  &__header-container-with-chat {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0px 60px 16px;
    gap: 16px;
    background: rgb(26 26 36 / 70%);
  }

  &__title-with-chat {
    color: var(--primary-ey-white-ffffff, #fff);
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 2.5rem;
  }
  &__button {
    display: inline-flex;
    height: 3.75rem;
    padding: 0.6875rem 2rem;
    justify-content: center;
    align-items: center;
    gap: 0.125rem;
    flex-shrink: 0;
    border-radius: 0.625rem;
    background: var(--primary-yellow, #ffe600);
    box-shadow: 0px 6px 25px 0px rgba(0, 0, 0, 0.4);
    border-width: 0;
  }
  &__button-with-chat {
    display: inline-flex;
    height: 3rem;
    border-radius: 0.625rem;
    padding: 0.6875rem 2rem;
    justify-content: center;
    align-items: center;
    gap: 0.125rem;
    flex-shrink: 0;
    background: var(--primary-yellow, #ffe600);
    box-shadow: 0px 6px 25px 0px rgba(0, 0, 0, 0.4);
    border-width: 0;
  }
  &__button-title {
    color: var(--grey-500, #2e2e38);
    text-align: center;
    font-family: EYInterstate;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 2.25rem;
    text-transform: capitalize;
  }
  &__with-button-title {
    color: var(--grey-500, #2e2e38);
    text-align: center;
    font-family: EYInterstate;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 2.25rem;
    text-transform: capitalize;
  }
  &__bar-chat {
    width: toRem(32px);
    height: toRem(32px);
  }
  &__with-bar-chat {
    width: toRem(16px);
    height: toRem(16px);
  }
  &__toast-container-public {
    position: absolute;
    top: -187px;
    bottom: auto;
    width: 30%;
    right: 10%;
    left: auto;
    z-index: 9999999999;
  }

  .loader-position {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    margin: 0;
    text-align: center;
    flex-direction: column;

    .loader {
      border: 5px solid #f3f3f3;
      border-radius: 50%;
      border-top: 5px solid #2e2e38;
      width: 50px;
      height: 50px;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
    }

    /* Safari */
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

// .hierarchy-box {
.lazy-loading-load-indicator {
  text-align: center !important;
}

.hierarchy-checkboxes {
  height: 150px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 8px;
  margin-right: 5px;
  overflow: auto;
  font-size: 14px;
  background-color: var(--primary-ey-white-ffffff, #fff);
}

.hierarchy-checkboxes-empty {
  height: 8px;
  margin: 0;
  overflow: hidden;
}
// }
.react-checkbox-tree > ol ol .rct-node-leaf::before {
  position: absolute;
  left: -16px;
}
.react-checkbox-tree {
  &:not(.rct-native-display) {
    input {
      display: none;
    }
  }

  .rct-icon {
    font-family: 'Font Awesome 5 Free', 'FontAwesome', sans-serif;
    color: $gray-5;
  }

  &.rct-icons-fa5 {
    .rct-checkbox {
      .rct-icon {
        position: relative;
        top: 2px;
        width: 15px;
        height: 15px;
        display: inline-block;
        border: 1px solid #2e2e38;
        font-family: 'Font Awesome 5 Free', 'FontAwesome', sans-serif;
      }

      .rct-icon-check {
        color: $black;
        border: 1px solid #2e2e38;

        &::before {
          content: '\f00c';
          position: absolute;
          top: 0px;
          font-size: 11px;
          font-weight: 900;
          left: 1px;
        }
      }
    }
  }

  ol {
    padding-left: $as-tree-struct-left-padding;
    margin-bottom: 0;
    list-style: none;

    label,
    .rct-bare-label {
      color: $black;
    }

    .rct-bare-label {
      .rct-title {
        margin-left: 0;
      }
    }
  }

  > ol {
    padding-left: 0;

    ol {
      margin-left: 21px;
      padding-top: 6px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        height: 9px;
        left: -1px;
        width: 2px;
        bottom: 0;
      }

      li {
        position: relative;
      }
    }
  }

  ul {
    list-style: none;
  }

  // .rct-node-parent {
  //   .rct-collapse-btn {
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     width: 24px;
  //     height: 24px;
  //     margin-right: 9px;
  //     border: none;
  //     line-height: 1;
  //     margin-top: 0;
  //     background: #fff;

  //     &:focus {
  //       outline: none;
  //     }

  //     span {
  //       font-size: 8px;
  //       color: $gray-10;
  //     }
  //     .fa-chevron-down {
  //       -webkit-transform: rotate(-90deg);
  //       -moz-transform: rotate(-90deg);
  //       -o-transform: rotate(-90deg);
  //       -ms-transform: rotate(-90deg);
  //       transform: rotate(-90deg);
  //       color: $gray-50;
  //     }
  //     .fa-chevron-up {
  //       -webkit-transform: rotate(-180deg);
  //       -moz-transform: rotate(-180deg);
  //       -o-transform: rotate(-180deg);
  //       -ms-transform: rotate(-180deg);
  //       transform: rotate(-180deg);
  //       color: $gray-50;
  //     }
  //   }

  //   .rct-node-parent {
  //     .rct-collapse-btn {
  //       margin-right: 3px;
  //     }
  //   }
  // }

  .rct-text {
    display: flex;
    margin-bottom: 5px;

    > label {
      display: flex;
      margin-bottom: 0;
      cursor: pointer;
    }

    button {
      margin-top: 5px;
    }
  }

  .rct-title {
    margin-left: 10px;
    word-break: break-word;
    font-size: 12px;
    font-weight: 300;
  }
}

.custom-scrollbar {
  @include custom-scrollbar-theme;
}
.filter-footer {
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 16px;
}
.hierarchy-search-filter {
  display: flex;
  // align-items: center;
  justify-content: center;
  border-bottom: 1px solid #fff;
  padding: 3px 0px 0px;
  border: none;
  img {
    border-bottom: 1px solid #000000;
  }
  i {
    font-size: 0.8125rem;
    color: $white;
    padding: 5px;
  }

  .filter-remove {
    font-size: 1rem;
    cursor: pointer;
  }

  .input {
    // margin-right: 6px;
    font-size: 14px;
    width: 100%;
    height: 44px;
    font-family: 'EYInterstate', 'Segoe UI', sans-serif;
    color: #000000;
    border: none;
    border-bottom: 1px solid #000000;

    &::placeholder {
      color: $black;
    }
  }
}

.se-close-icon {
  cursor: pointer;
}
.tableoverlapsector {
  z-index: 9999;
  left: 0;
}

.tableoverlapsector1 {
  width: 140%;
  background: var(--primary-ey-white-ffffff, #fff);
  border-radius: 7px;
  position: absolute;
  z-index: 9999;
  justify-content: center;
  left: 0;
  border: 1px solid rgb(204, 204, 204);
}
.tableoverlapsector1-with-chat {
  width: 420px;
  background: var(--primary-ey-white-ffffff, #fff);
  border-radius: 7px;
  position: absolute;
  z-index: 9999;
  justify-content: center;
  left: 0;
  border: 1px solid rgb(204, 204, 204);
}
.tableoverlapsector1-with-width-chat {
  @extend .tableoverlapsector1-with-chat;
  @media screen and (min-width: 1000px) and (max-width: 1300px) {
    width: 420px;
  }
}
.tableoverlapgeography {
  width: 100%;
  background: var(--primary-ey-white-ffffff, #fff);
  border-radius: 7px;
  position: absolute;
  z-index: 9999;
  left: 0;
  border: 1px solid rgb(204, 204, 204);
}
.tableoverlapgeography-with-chat {
  position: absolute;
  z-index: 9999;
  width: 100%;
  justify-content: center;
  width: 100%;
  background: var(--primary-ey-white-ffffff, #fff);
  border-radius: 7px;
  border: 1px solid rgb(204, 204, 204);
}
.tableoverlapgeography-with-width-chat {
  @extend .tableoverlapgeography-with-chat;
  @media screen and (min-width: 1000px) and (max-width: 1300px) {
    width: 140%;
  }
}

.tableoverlapgeograpyh1 {
  position: fixed;
  top: 34%;
  z-index: 9999;
  width: 100%;
  justify-content: center;
  margin-left: 5%;
  left: 350px;
  top: 18%;
}

.thin-white-line {
  border-bottom: 0.3px solid #607d8b;
  padding-bottom: 6px;
  color: black;
}
.insight-dropdowns .Dropdown-placeholder {
  top: 0px;
}

.stop-generation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.stop-generation-button {
  width: 132px;
  height: 36px;
  background-color: #2e2e38;
  color: #ffffff;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}
