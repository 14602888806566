.sectorgeography {
  max-height: 165px;
}

.sectorgeography ol li {
  margin-bottom: 0px;
}
// .rct-disabled .rct-checkbox {
//   background-color: #f0f0f0; /* Change this to your desired color */
//   cursor: not-allowed;
// }
.rct-disabled .rct-checkbox .rct-icon {
  border-color: rgb(223, 227, 232) !important;
  color: rgb(223, 227, 232);
  background-color: rgb(223, 227, 232);
  cursor: not-allowed;
  pointer-events: all !important;
}
