.engagementtable {
  &__nameroles {
    border-bottom: 1px solid #e1e1e6;
    padding: 5px 16px;
    font-size: 13px;
    height: 30px;
    max-height: 120px;
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }
  .double {
    height: 60px;
  }
  .triple {
    height: 85px;
  }
  
  &__sort {
    border: navajowhite;
    padding: 0px 0px 0px 6px;
    background: #e1e1e6;
  }
  &__sort-col {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
  }
  &__nameroles:last-child {
    border: none !important;
    background-color: #ffffff;
    height: 30px;
    max-height: 120px;
  }
  .double:last-child {
    border: none !important;
    background-color: #ffffff;
    height: 60px;
    max-height: 120px;
  }
  .triple:last-child {
    border: none !important;
    background-color: #ffffff;
    height: 85px;
    max-height: 120px;
  }
  & table.engagementtable-table {
    margin-left: 10px;
    margin-top: 16px;
    border-spacing: 0;
    border: 1px solid #e1e1e6;
    table-layout: auto;
    background-color: #585860;
    border-radius: 4px;
    border-collapse: separate;
    &__footer {
      text-align: right;
    }

    .files-download-btn {
      cursor: pointer;
      color: #155cb4;
      text-decoration: underline;
    }
    .files-slide-download-btn {
      // cursor: pointer;
    }
    tr:nth-child(odd) {
      background-color: #fff;
    }
    tr:nth-child(even) {
      background-color: #fff;
    }

    th {
      padding: 5px 20px;
      background-color: #e1e1e6;
      min-width: 170px;
      width: 60%;
      color: #747480;
      font-size: 14px;
      font-weight: bolder;
      text-wrap: nowrap;
    }

    th,
    td {
      border-bottom: 1px solid #e1e1e6;
      border-right: 1px solid #e1e1e6;
    }

    td {
      padding: 0px 0px;
      text-align: left;
      vertical-align: middle;
    }

    td.disclaimer {
      font-size: 11px;
    }
    td {
      .btn-show {
        color: #155cb4;
        background: transparent;
        border: none;
        text-decoration: none;
        align-items: right;
        cursor: pointer;
      }
    }
    td {
      .table__author {
        text-decoration: underline;
        color: #155cb4;
        cursor: pointer;
      }
      .center-text {
        padding: 0px 0px 0px 12px;
      }
    }
    td.table__footer {
      text-align: right;
      padding: 4px 0px;
    }
  }
  .table {
    &__collapse {
      span {
        cursor: pointer;
        padding: 0px 10px;
        font-weight: bold;
      }
      padding: 10px 0;
      background-color: #c4c4cd;
      background-color: 1px solid #747480;
      color: #2e2e38;
    }
  }
}
